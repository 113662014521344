import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { handleRequest } from "../../../../services";
import { SIGNIN, SOCIAL_LOGIN } from "../../../../config";
import Spinner from "../../../../components/loader/loader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { LoginSocialFacebook } from "reactjs-social-login";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUpLayout from "../../signup";
const SignIn = (props) => {
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isTermsAgreed, setIsTermsAgreed] = useState("");
  const [check, setCheck] = useState(false)
  const { userData, setUserData, phone, setPhone } = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const [flag, setFlag] = useState('')
  const handleOnChange = (value, country) => {
    setErrorMessage(false)
    setPhone(value);
    setIsValidPhone("");
    localStorage.setItem("flag", country.countryCode)
    setFlag(country.countryCode)
  };


  // let message = localStorage.getItem("message")

  // useEffect(() => {
  //   if (message) {
  //     toast(message, {
  //       style: { color: 'white' }
  //     }); localStorage.removeItem("message")
  //   }
  //   return () => { 
  //     message = null
  //   }
  // }, [])

  const googleLoginSuccess = (codeResponse) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res, "response..");
        handleSocialLogin(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleSocialLogin = async (data) => {
    let userId = localStorage.getItem("uniqueUserId");
    if (!userId) {
      userId = generateUniqueId();
      localStorage.setItem("uniqueUserId", userId);
      localStorage.setItem("uniqueId", data.id)
    }
    const apiData = {
      username: data.name,
      login_type: "1",
      unique_id: data.id,
      device_type: "web",
      device_token: userId,
    };

    try {
      const response = await handleRequest("POST", SOCIAL_LOGIN, apiData);
      if (response.code === 200) {
        if (response.body.token && response.body.userdata) {
          localStorage.setItem("userData", JSON.stringify(response.body));
          // setUserData(response.body.userdata);

          if (response.body.userdata.is_profile_complete !== 0 && response.body.userdata.is_profile_complete !== "0") {
            const userData = JSON.parse(localStorage.getItem("userData"))
            localStorage.setItem("token", JSON.stringify(userData.token));
            navigate(ROUTES.HOME);
          } else {
            // navigate(ROUTES.SIGNUP);
            setCheck(true)
          }
        } else {
          localStorage.setItem("userData", JSON.stringify(response.body));
          setUserData(response.body);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleTermsChange = () => {
    setTermsAgreed(!termsAgreed);
    // setIsAgreed("");
  };
  const handleValidation = () => {
    let validate = true;
    if (!phone.trim()) {
      setErrorMessage(true)
      validate = false;
    }
    else if (phone.length <= 10) {
      setErrorMessage(true)
      validate = false;
    }
    else if (!termsAgreed) {
      toast("Please agree to the terms and conditions", {
        style: { color: 'white' }
      });
      validate = false;
    }
    return validate;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      account_type: "1",
      // mobile_no: `+${phone}`,
      mobile_no: `+${phone}`,
      flag: flag
    };
    if (handleValidation()) {

      setLoading(true);
      try {
        const response = await handleRequest("POST", SIGNIN, data);
        if (response.code === 200) {
          if (response.body.token && response.body.userdata) {
            localStorage.setItem("userData", JSON.stringify(response.body));
            setUserData(response.body.userdata);

          } else {
            localStorage.setItem("userData", JSON.stringify(response.body));
            setUserData(response.body);
          }
        }
        if (response.code == 400) {
          toast(response.message, {
            style: { color: 'white' }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const generateUniqueId = () => {
    return `user_${Date.now()}`;
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => googleLoginSuccess(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  const handleTryFree = () => {
    if (!termsAgreed) {
      toast("Please agree to the terms and conditions", {
        style: { color: 'white' }
      });
    } else {
      navigate(ROUTES.TRYFREE)
    }
  }

  return (
    <>
      {
        check ? <SignUpLayout /> :
          <div className="bg-white w-100 box-shadow login-radius">
            <div className="heading">
              <h2 className="boughies pb-2">Welcome</h2>
              <p className="fw-400">A new and exciting way to meet people</p>
            </div>
            <div className="upper-space">
              <form onSubmit={handleSubmit}>
                <div className="form-group position-relative">
                  <PhoneInput
                    country={"gb"}
                    value={phone}
                    onChange={handleOnChange}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleSubmit(e)
                      }
                    }}
                    inputClass={errorMessage ? "input-field w-100 error-mesg " : "input-field w-100 "}
                    enableSearch={true}
                  />
                </div>
                {/* {isValidPhone && <p className="text-danger">{isValidPhone}</p>} */}
                <div className="mt-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={termsAgreed}
                    onChange={handleTermsChange}
                  />
                  <label className="fw-400">
                    I am  <span className="sec-theme fw-500"> 16 or over  </span> and agree to the  <span className="sec-theme fw-500" onClick={() => openLink('https://asocial.chat/terms-of-service/')}
                      style={{ cursor: 'pointer' }}> Terms of Service </span>
                    and <span className="sec-theme fw-500" onClick={() => openLink("https://asocial.chat/privacy-policy/")}
                      style={{ cursor: 'pointer' }}> Privacy Policy</span>
                  </label>
                </div>
                {/* {isTermsAgreed && <p className="text-danger">{isTermsAgreed}</p>} */}
                <div className="top-space">
                  {loading ? (
                    <div className="loader-style">
                      <Spinner />
                    </div>
                  ) : (
                    <button type="submit" className="gradient-btn border-0 w-100">
                      Get Verification Code
                    </button>
                  )}
                </div>
                <div className="margin-top-4">
                  <div className="text-center">
                    <p className="with-bg padding-both">
                      <span className="loginwith fw-400 para-text">Login With</span>
                    </p>
                    <div className="social-link py-3 d-flex justify-content-center align-items-center gap-5">
                      <span>
                        <LoginSocialFacebook
                          appId={import.meta.env.VITE_APP_ID}
                          onResolve={(response) => {
                            console.log(response);
                            const facebookData = {
                              name: response?.data?.name,
                              id: response?.data?.id
                            };
                            handleSocialLogin(facebookData);
                          }}

                          onReject={(error) => {
                            console.log(error);
                          }}
                        >
                          <img src={IMAGES.FBLOGIN} className="img-fluid" />
                        </LoginSocialFacebook>


                      </span>
                      <span onClick={login}>
                        <img src={IMAGES.GOOGLELOGIN} className="img-fluid" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="skip-wrapper margin-top-4">

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={handleTryFree}
                    className="skip-btn w-100 fw-500 f18-size "
                  >
                    {" "}
                    Skip Signup
                  </div>
                </div>
                <ToastContainer />
              </form>
            </div>
          </div>
      }

    </>
  );
};

export default SignIn;
