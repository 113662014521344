import React from 'react'
import { IMAGES } from '../../components/images'
const ImagePreview = ({setImageOpen,url}) => {

  return (
    <div className="myaccount-gallery  modal-gallery">
    <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered ">
      <div className="myaccount-content content-gallery image-p-0">
        <div className=" border-0">
          <div class="image-preview-close" onClick={()=>setImageOpen(false)} style={{cursor:"pointer"}}>
            <img src={IMAGES.WHITE_ICON} class="img-fluid"/></div>
        </div>
       <img src={url} style={{ height: "100%",width:"100%",objectFit:"contain"}}/>
        </div>
      </div>
    </div>
  )
}

export default ImagePreview