import React, { useEffect, useRef, useState } from "react";
import { IMAGES } from "../../../../components/images";
import SetProfilePic from "../../../modals/setProfilePic";
import { Link } from "react-router-dom";
import { IMAGEURL, UPLOADIMAGE } from "../../../../config";
import { handleRequest } from "../../../../services";
import html2canvas from "html2canvas";

const avatars = [
  IMAGES.AVATARFEMALE,
  IMAGES.AVATARMALE,
  IMAGES.SHORT_AVATAR,
  IMAGES.AVTAR_FOUR,
  IMAGES.AVATAR6,
  IMAGES.AVATAR7,
  IMAGES.AVATAR8,
  IMAGES.AVTAR_ONE,
  IMAGES.AVTAR_SEVEN,
  IMAGES.AVATAR_10,
  IMAGES.AVATAR_12,
  IMAGES.AVATAR_14,
  IMAGES.AVATAR_13,
  IMAGES.AVATAR_15,
  IMAGES.AVATAR_11,
  IMAGES.AVATAR5,
  IMAGES.AVTAR_THREE,
  IMAGES.AVATARFEMALE,
  IMAGES.AVTAR_TWO,
  IMAGES.AVTAR_FIVE,
  IMAGES.AVTAR_SIX,
];
const ThirdStep = (props) => {
  const {
    setStep,
    setFormData,
    formData,
    setSelectedAvatarIndex,
    selectedAvatarIndex,
    isImageUploaded,
    setIsImageUploaded,
  } = props;
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [error, setError] = useState(null);
  const [viewCamImage, setViewCamImage] = useState(false);
  const [viewGalleryImage, setViewGalleryImage] = useState(false);
  const file_upload = localStorage.getItem("fileUpload")
  const avatarRefs = useRef(avatars.map(() => React.createRef()));

  const handleImageUploadModal = () => {
    setUploadImageModal(true);
  };

  const handleAvatarClick = (index) => {
    console.log("first", index)
    setSelectedAvatarIndex(index);
    setIsImageUploaded(false);
    imageCreation(avatarRefs.current[index].current);
    if (error) {
      setError(null);
    }
  };
  console.log(selectedAvatarIndex, "selectedAvatarIndex")
  const imageCreation = async (img) => {
    if (!img) {
      return;
    }

    const capturedImageUrl = await captureDivAsImage(img);
    uploadImage(capturedImageUrl);
  };

  const captureDivAsImage = async (imageDIv) => {
    const divToCapture = imageDIv;
    const canvas = await html2canvas(divToCapture);

    return canvas.toDataURL("image/png");
  };

  function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== "string") {
      return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  async function uploadImage(b64img) {
    var file = await urltoFile(b64img, "name.png", base64MimeType(b64img));
    handleFileUploadToServer(file);
  }

  const handleFileUploadToServer = async (file) => {
    const formDataImage = new FormData();
    formDataImage.append("image", file);
    try {
      const response = await handleRequest("POST", UPLOADIMAGE, formDataImage, true);
      if (response.code === 200) {
        setFormData({ ...formData, avatar: response.body });
        setIsImageUploaded(true);
        localStorage.setItem("fileUpload", 1)
        // setSelectedAvatarIndex(null);
        setViewGalleryImage(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAvatars = avatars.map((avatar, index) => (
    <div
      key={`avatar${index}`}
      style={{ cursor: "pointer" }}
      onClick={() => handleAvatarClick(index)}
      className="col-6 col-lg-4 mb-3"
    >
      <div
        className={`gender-inner ${selectedAvatarIndex === index
          ? "active"
          : ""
          }`}
      >
        <img
          ref={avatarRefs.current[index]}
          src={avatar}
          alt={`Avatar ${index}`}
          className="img-fluid avatar-img"
        />
      </div>
    </div>
  ));
  const handleValidation = () => {
    let validate = true;

    if (selectedAvatarIndex === undefined || selectedAvatarIndex === null) {
      setError("Please select an avatar");
      validate = false;
    }

    return validate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setStep(4);
    }
  };

  console.log(selectedAvatarIndex, "selectedAvatarIndex")
  return (
    <>
      <div className="heading">
        <h3 className="">Avatar</h3>
        <p className="fw-400">Select the avatar you like</p>
      </div>
      <div className="padding-top-4">
        <form onSubmit={handleSubmit}>
          <div className="pt-4 pb-3 avatar-height">
            <div className="row pe-3">
              <div className="col-6 col-lg-4 mb-3">
                <Link
                  onClick={handleImageUploadModal}
                  className=" avatar-box"
                  data-bs-toggle="modal"
                  data-bs-target="#avatarmodal"
                >
                  <div
                    className={`avatar-box gender-inner ${file_upload == 2 || file_upload == 3
                      ? "active" : ""
                      }`}
                  >
                    <img
                      className={`avatar-img ${file_upload == 2 || file_upload == 3
                        ? "upload-preview" : ""
                        }`}
                      src={
                        file_upload == 2 || file_upload == 3
                          ? IMAGEURL + formData.avatar
                          : IMAGES.CAMERAICON
                      }
                    />
                  </div>
                </Link>
              </div>
              {renderAvatars}
            </div>
          </div>
          {error && <p className="text-danger">{error}</p>}
          <div className="top-space">
            <button className="gradient-btn border-0 w-100">Submit</button>
          </div>
        </form>
      </div>
      {uploadImageModal && (
        <SetProfilePic
          viewCamImage={viewCamImage}
          setViewCamImage={setViewCamImage}
          viewGalleryImage={viewGalleryImage}
          setViewGalleryImage={setViewGalleryImage}
          setUploadImageModal={setUploadImageModal}
          setFormData={setFormData}
          formData={formData}
          setIsImageUploaded={setIsImageUploaded}
          isImageUploaded={isImageUploaded}
          setSelectedAvatarIndex={setSelectedAvatarIndex}
          setError={setError}
          error={error}
        />
      )}
    </>
  );
};

export default ThirdStep;
