import React, { useState, useRef, useEffect } from "react";
import { IMAGES } from "../../../components/images";

import SetProfilePic from "../../modals/setProfilePic";
import { handleRequest } from "../../../services";
import { IMAGEURL, UPLOADIMAGE } from "../../../config";
import html2canvas from "html2canvas";

const avatars = [
  IMAGES.AVATARFEMALE,
  IMAGES.AVATARMALE,
  IMAGES.SHORT_AVATAR,
  IMAGES.AVTAR_FOUR,
  IMAGES.AVATAR6,
  IMAGES.AVATAR7,
  IMAGES.AVATAR8,
  IMAGES.AVTAR_ONE,
  IMAGES.AVTAR_SEVEN,
  IMAGES.AVATAR_10,
  IMAGES.AVATAR_12,
  IMAGES.AVATAR_14,
  IMAGES.AVATAR_13,
  IMAGES.AVATAR_15,
  IMAGES.AVATAR_11,
  IMAGES.AVATAR5,
  IMAGES.AVTAR_THREE,
  IMAGES.AVATARFEMALE,
  IMAGES.AVTAR_TWO,
  IMAGES.AVTAR_FIVE,
  IMAGES.AVTAR_SIX,
 
];

const ProfileImage = (props) => {
  const { handleFileUpload, setChangeImage, handleClose,isImageUploaded, setIsImageUploaded,editDetails,setEditDetails }  = props
  const [captureImage, setCaptureImage] = useState(null);
  const [onCamContainer, setOnCamContainer] = useState(false);
  const [viewCamImage, setViewCamImage] = useState(false);
  const [viewGalleryImage, setViewGalleryImage] = useState(false);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null)
  const avatarRefs = useRef( avatars.map(() => React.createRef()));
  if (captureImage != null) {
    handleFileUpload(captureImage);
  }

  const handleAvatarClick = async (index) => {
    setSelectedAvatarIndex(index);
    setIsImageUploaded(false);
    await imageCreation(avatarRefs.current[index].current);
  };

  const imageCreation = async (img) => {
    if (!img) {
      return;
    }

    const capturedImageUrl = await captureDivAsImage(img);
    uploadImage(capturedImageUrl);
  };

  const captureDivAsImage = async (imageDIv) => {
    const divToCapture = imageDIv;
    const canvas = await html2canvas(divToCapture);

    return canvas.toDataURL("image/png");
  };

  function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== "string") {
      return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  async function uploadImage(b64img) {
    var file = await urltoFile(b64img, "name.png", base64MimeType(b64img));
    await handleFileUpload(file);
  }


  const renderAvatars = avatars.map((avatar, index) => (
    <div
      key={`avatar${index}`}
      style={{ cursor: "pointer" }}
      onClick={() => handleAvatarClick(index)}
      className="col-12 col-sm-6 mb-2 mx-auto"
    >
      <div
        className={`gender-inner ${
          selectedAvatarIndex === index && !viewCamImage && !viewGalleryImage
            ? "active"
            : ""
        }`}
      >
        <img
          ref={avatarRefs.current[index]}
          src={avatar}
          alt={`Avatar ${index}`}
          className="img-fluid avatar-img"
        />
      </div>
    </div>
  ));

  // enable camra and gallery container

  function openCamraContainer() {
    setOnCamContainer(true);
  }

  // outside click handle
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setChangeImage(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);


  return (
    <>
      <div className="top-profile-container" ref={wrapperRef}>
        <div className="pt-4 profile-top-container ">
          <div className="col-12 col-sm-6 mb-2 mx-auto">
            <div className="cam-icon-conatiner gender-inner">
              <img
                  className={`img-fluid avatar-img ${
                    isImageUploaded ? "upload-preview" : ""
                  }`}
                  src={
                    isImageUploaded
                      ? IMAGEURL + editDetails.avatar
                      : IMAGES.CAMERAICON
                  }
                onClick={openCamraContainer}
                
              />
            </div>
          </div>

          {renderAvatars}
        </div>

        <div>
          {onCamContainer == true && (
            <SetProfilePic
              handleClose={handleClose}
              setOnCamContainer={setOnCamContainer}
              setCaptureImage={setCaptureImage}
              setUploadImageModal={setOnCamContainer}
              formData={editDetails}
              viewGalleryImage={viewGalleryImage}
              setViewGalleryImage={setViewGalleryImage}
              setSelectedAvatarIndex={setSelectedAvatarIndex}
              selectedAvatarIndex={selectedAvatarIndex}
              setFormData={setEditDetails}
              isImageUploaded={isImageUploaded}
              setIsImageUploaded={setIsImageUploaded}
              setViewCamImage={setViewCamImage}
              viewCamImage={viewCamImage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileImage;
