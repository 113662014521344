import React, { useState } from "react";
import { IMAGES } from "../../../../components/images";
import dayjs from "dayjs";
import { ToastContainer, toast } from 'react-toastify';
const FirstStep = (props) => {
  const { setFormData, formData, setStep } = props;
  const [errors, setErrors] = useState(null);
  // const [ageAgreed, setAgeAgreed] = useState(false);

  const handleChange = (e) => {
    setErrors(null)
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
    if (!errors) {
      setErrors(null);
    }
  };

  const handleDateChange = (date) => {
    //dayjs(date).format('YYYY-MM-DD')
    setErrors(null)
    setFormData({ ...formData, dateOfBirth: date });
    if (!errors) {
      setErrors(null);
    }
  };

  const handleValidation = () => {
    let validate = true;
    let error = {};
    const currentDate = dayjs();
    if (formData?.screenName?.trim()?.length === 0) {
      error.screenName = "Please enter screen name";
      validate = false;
    } else if (formData?.screenName?.length < 3) {
      error.screenName = "Screen name should be at least 3 characters long";
      validate = false;
    }
    // if (!formData?.dateOfBirth) {
    //   error.dateOfBirth = "Please enter date of birth";
    //   validate = false;
    // } else if (currentDate?.diff(formData?.dateOfBirth, "year", true) < 13) {
    //   error.dateOfBirth = "You must be 13 or older to use Asocial";
    //   validate = false;
    // } 
    setErrors(error);
    return validate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      // if (!ageAgreed) {
      //   toast("Age must me 16 or above", {
      //     style: { color: 'white' }
      //   });
      // }
      // else {
      setStep(2);
      // }
    }
  };

  // const handleTermsChange = () => {
  //   setAgeAgreed(!ageAgreed);
  // };
  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="">
          <h3 className="first-head">Your Screen Name</h3>
          <label className="pb-4">Enter your Name.</label>
          <div className="form-group position-relative">
            <input
              name="screenName"
              type="text"
              placeholder="Screen name"
              value={formData?.screenName}
              onChange={handleChange}
              className="input-field w-100 left-padding"
            />
            <div className="phone-icon">
              <img src={IMAGES.USERNAME} className="img-fluid" />
            </div>
            {errors?.screenName && (
              <p className="text-danger">{errors?.screenName}</p>
            )}
          </div>
        </div>

        {/* <h3 className="first-head">Date of Birth</h3>
          <label className="pb-4">You must be 13 or older to use Asocial</label>
          <div className="form-group position-relative">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                value={formData?.dateOfBirth}
                className="input-field w-100 left-padding"
                onChange={handleDateChange}
              />
            </LocalizationProvider>

            <div className="phone-icon">
              <img src={IMAGES.CALENDER} className="img-fluid" />
            </div>
            {errors?.dateOfBirth && (
              <p className="text-danger">{errors?.dateOfBirth}</p>
            )}
          </div> */}
        {/* <div className="mt-3 form-check pl-0" style={{ marginLeft: '10px' }}>
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            checked={ageAgreed}
            onChange={handleTermsChange}
          />
          <label className="fw-400">
            I confirm that I am<span className="sec-theme fw-500" > 16 years </span>
            of age or <span className="sec-theme fw-500">older</span>
          </label>
        </div> */}
        <div className="top-space">
          <button type="submit" className="gradient-btn border-0 w-100">
            Submit
          </button>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
};

export default FirstStep;
