import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../../components/images";
import { ACCEPT_REJECT, CREATE_ROOM, FRIEND_UNFRIEND, GET_ALL_CONNECTION, IMAGEURL } from "../../../../config";
import { handleRequest } from "../../../../services";
import Spinner from "../../../../components/loader/loader";

const NewRequest = () => {
  const [friendRequestList, setFriendRequestList] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const getFriendRequestList = async () => {
    setIsLoading(true);

    try {
      const response = await handleRequest("GET", `${GET_ALL_CONNECTION}?type=1`);
      setFriendRequestList(response.body)

    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);

  }
  const acceptRejectFriendRequest = async (id, status) => {
    const data = {
      "sender_id": id,
      "status": status
    }
    try {
      const response = await handleRequest("POST", ACCEPT_REJECT, data);
      if (status === 2 && response.code === 200) {
        try {
          const createRoomResponse = await handleRequest("GET", CREATE_ROOM + id);
        } catch (error) {
          console.log(error);
        }
      }
      getFriendRequestList();
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    getFriendRequestList();
  }, [])
  return (
    <>
      {isLoading ? <div className="web-cam inner-spinner"><Spinner /></div> : <div className="tabs-inner-content mt-4">
        <h4 className="text-color ">New Request ({friendRequestList?.length})</h4>
        <div className="scroll-inner">
          <div className="row mt-4">
            {friendRequestList !== null && friendRequestList?.filter(item => item.username !== null)?.length > 0 ? friendRequestList?.filter(item => item.username !== null)?.map((request) => (
              <div className=" col-lg-6 col-xl-4 col-xxl-4 mb-4">
                <div className="newrequest-box">
                  <div className="profile-online position-relative">
                    <img src={request.avatar ? IMAGEURL + request.avatar : IMAGES.USER_ICON} />
                     {
                      request.is_online == 1 && 
                      <span className="green-dot"></span>
                     } 
                  </div>
                  <div className="">
                    <p className="mb-0 online-text text-color fw-500 one-line">
                      {request.username}
                    </p>
                    <p className="mb-2 one-line f12-size">
                      {" "}
                      Sent you friend request
                    </p>
                    <div className="d-flex align-items-center">
                      <button
                        onClick={() => acceptRejectFriendRequest(request.friend_id, 1)}
                        type="button"
                        className="cancel-btn f14-size fw-500 text-color me-2"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => acceptRejectFriendRequest(request.friend_id, 2)}
                        type="button"
                        className="sec-bg cancel-btn f14-size fw-500 text-color "
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                  <div className="timing-request">
                    <p className="mb-0  f12-size fw-400">11:20PM</p>
                  </div>
                </div>
              </div>
            )) :
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <h4>No new request yet</h4>
              </div>
            }
          </div>
        </div>
      </div>}
    </>
  );
};

export default NewRequest;
