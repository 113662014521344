import React, { useEffect, useState, useRef } from "react";
import { IMAGES } from "../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { handleRequest } from "../../services";
import { GET_MY_DETAIL, IMAGEURL, SET_YOUR_PROFILE, UPLOADIMAGE, GET_MATCHED_INTEREST_LISTING } from "../../config";
import EnterYourOwn from "../modals/enterOwnInerestsModal";
import ThirdStep from "../auth/signup/components/thirdStep";
import ProfileImage from "../home/components/ProfileImage";
import Spinner from "../../components/loader/loader";
import FourthStep from "../auth/signup/components/fourthStep";

const fields = {
  interests: {},
}

const MyAccount = (props) => {
  const { setMyAccountModal, userDetail, toast, fetchDetails } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingInterest, setIsLoadingInterest] = useState(false)
  const [showOwnInterestModal, setShowOwnInterestModal] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [editDetails, setEditDetails] = useState(userDetail);
  const [editInterestsModal, setEditInterestsModal] = useState(false);
  const [formData, setFormData] = useState(fields);
  const [changeImage, setChangeImage] = useState(false);
  const [errors, setErrors] = useState(null);
  const [interests, setInterests] = useState([])
  const [catIds, setCatIds] = useState([])
  const [owninterest, setOwnInterest] = useState("")

  const getInterestList = async () => {
    try {
      setIsLoadingInterest(true)
      const response = await handleRequest("GET", GET_MATCHED_INTEREST_LISTING);
      if (response.code == 200) setIsLoadingInterest(false)

      const data = response.body;
      const transformedData = data
        .filter(item => item.type !== 2)
        .map(item => ({
          category: item.name,
          cat_id: item.id,
          subcategories: item.intrest_list.map(sub => sub.name),
          type: item.type
        }));
      setInterests(transformedData);
      const ownInterest = data.find(item => item.type === 2);
      setOwnInterest(ownInterest?.name)
      console.log(response.body)
      const categoryToIdMap = data.reduce((map, item) => {
        map[item.name] = item.id;
        return map;
      }, {});
      setCatIds(categoryToIdMap)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInterestList();
  }, []);

  const handleClose = () => {
    setMyAccountModal(false);
  }
  const transformInterestsList = (interestsList) => {
    const formDataCopy = { interests: {} };
    interestsList.forEach(item => {
      const { category_name, interests, type } = item;
      console.log(item, "item", formDataCopy, interestsList)
      const formattedInterests = interests.split(',').map(interest => interest.trim());
      if (type === 2) {
        formDataCopy.interests['ownInterests'] = formattedInterests;
      } else {
        formDataCopy.interests[category_name] = formattedInterests;
      }
    });

    return formDataCopy;
  };

  const handleFormData = () => {
    const formDataCopy = transformInterestsList(editDetails.intrests_list);
    setFormData(formDataCopy);
  };

  const handleChange = (e) => {
    setErrors('')
    const { name, value } = e.target;
    setEditDetails({
      ...editDetails,
      [name]: value,
    });
  }

  const getCategoryMapping = (interestsList) => {
    return interestsList.reduce((acc, { category_name }) => {
      if (!acc.includes(category_name)) acc.push(category_name);
      return acc;
    }, []);
  };
  
  const getCategoryOrderMap = (interestsList) => {
    return interestsList.reduce((map, item, index) => {
      map[item.category] = index;
      return map;
    }, {});
  };

  const categoryMapping = getCategoryMapping(editDetails.intrests_list);
  const categoryOrderMap = getCategoryOrderMap(interests);

  const sortedCategoryMapping = categoryMapping.sort((a, b) => {
    const indexA = categoryOrderMap[a] !== undefined ? categoryOrderMap[a] : Infinity;
    const indexB = categoryOrderMap[b] !== undefined ? categoryOrderMap[b] : Infinity;
    return indexA - indexB;
  });


  const renderInterestSection = (categoryName, categoryLabel) => {
    const category = editDetails.intrests_list.find(interest => interest.category_name === categoryLabel);
    if (!category) return null;
    const interests = category.interests.split(",").map(interest => interest.trim()).filter(interest => interest.length > 0);

    return interests.length > 0 && (
      <div className="interest-inner pb-1 myaccount-line border-bottom-0" style={{ gap: '0px' }}>
        {interests.map((interestName) => (
          <div key={interestName} className="owninterest d-flex align-items-center mx-2 gap-3">
            <p className="text-white mb-0 fw-400">{interestName}</p>
            <img
              onClick={() => handleInterestRemoval(categoryName, interestName)}
              style={{ cursor: "pointer" }}
              src={IMAGES.WHITECROSS}
              className="pt-1 img-fluid"
            />
          </div>
        ))}
      </div>
    );
  };

  const renderInterests = () => {
    return sortedCategoryMapping.map(categoryName => {
      const category = editDetails.intrests_list.find(interest => interest.category_name === categoryName);
      return category ? renderInterestSection(categoryName, category.category_name) : null;
    });
  };

  const handleInterestRemoval = (categoryName, interestName) => {
    setEditDetails(prevDetails => {
      const updatedDetails = { ...prevDetails };
      if (updatedDetails.intrests_list) {
        const category = updatedDetails.intrests_list.find(item => item.category_name === categoryName);
        if (category) {
          const updatedInterests = category.interests
            .split(',')
            .map(interest => interest.trim())
            .filter(interest => interest !== interestName)
            .join(',');

          if (updatedInterests) {
            category.interests = updatedInterests;
          } else {
            updatedDetails.intrests_list = updatedDetails.intrests_list.filter(item => item.category_name !== categoryName);
          }
        }
      }
      return updatedDetails;
    });
  };


  const genderOptions = [
    { id: 1, label: "Male", image: IMAGES.MALE },
    { id: 2, label: "Female", image: IMAGES.FEMALE },
    { id: 3, label: "Nonbinary", image: IMAGES.NOBINARY },
  ];



  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await handleRequest("POST", UPLOADIMAGE, formData, true);
      if (response.code === 200) {
        setEditDetails({ ...editDetails, avatar: response.body });
        setIsImageUploaded(true);
      }
    } catch (error) {
      console.log('Error in uploading the Image', error);
    }
  };

  const handleValidation = () => {
    let validate = true;
    let error = {};
    if (!editDetails.username) {
      error.username = "Please enter name";
      validate = false;
    }
    setErrors(error);
    return validate;
  }

  const handleUpdate = async () => {
    const transformedArray = editDetails.intrests_list.map(item => {
      let cat_id = item.cat_id;
      if (item.category_name === "ownInterests") {
        cat_id = catIds[owninterest];
      } else {
        cat_id = catIds[item.category_name] || cat_id;
      }
      return {
        cat_id,
        interests: item.interests
      };
    });
    if (handleValidation()) {
      setIsLoading(true);
      try {
        const data = {
          username: editDetails.username,
          avatar: editDetails.avatar,
          interests_data: transformedArray
        };
        const response = await handleRequest("POST", SET_YOUR_PROFILE, data)
        if (response.success) {
          localStorage.setItem("token", JSON.stringify(response.body.token));
          fetchDetails();
          toast.info(response.message);
        }

      } catch (error) {
        console.log(error);
        setMyAccountModal(false)
      }
      setMyAccountModal(false)
      setIsLoading(false);
    }

  }


  function profileImageChange() {
    setChangeImage(true);
  }

  const handleEditInterestsModal = () => {
    setEditInterestsModal(true);
  }

  useEffect(() => {
    handleFormData();
  }, [editDetails])

  return (
    <div 
      className="myaccount myaccount-gallery "
      id="asocialaccount"
      aria-hidden="true"
    >
      <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered myprofile-modal">
        {
          isLoadingInterest ? <div className="loader-style">
            <Spinner />
          </div> :
            <div className="myaccount-content content-gallery position-relative">
              {!editInterestsModal ? <><div className=" border-0 position-relative text-center">
                <h3>Profile</h3>
                <button type="button" onClick={handleClose} className="border-0 bg-none close-btn">
                  <img src={IMAGES.CLOSSBUTTON} />
                </button>
              </div>
                <div className="myaccount-body pt-2">
                  <form>
                    <div className="uploadprofile position-relative mb-4">
                      <img
                        id="blah"
                        src={IMAGEURL + editDetails.avatar}
                        alt="profile"
                        className="myaccountprofile"
                        onClick={profileImageChange}
                      />

                      <p style={{ cursor: "pointer" }} onClick={profileImageChange} className="f14-size sec-theme text-decoration-underline pb-2 text-center">
                        Change Image
                      </p>
                    </div>
                    <div className="myaccoount-form pt-4 pb-4">
                      <div className="form-group d-flex gap-3 gap-sm-5 align-items-self-end">
                        <label className="">Name</label>
                        <input
                          type="text"
                          className="input-account"
                          placeholder="Harry"
                          name="username"
                          value={editDetails.username}
                          onChange={handleChange}
                        ></input>

                      </div>
                      {errors?.username && (
                        <p className="text-danger">{errors?.username}</p>
                      )}
                      <div className="form-group d-flex gap-3 gap-sm-5 align-items-self-end pt-4 mt-1">
                        <label className="">Gender</label>
                        <div className="d-flex gap-3">
                          {genderOptions.map(option => (
                            <div key={option.id} className="accountgender-box">
                              <div className={`accountgender-inner ${editDetails.gender === option.id && "active"}`}>
                                <img src={option.image} />
                              </div>
                              <p className="text-center f12-size fw-400 mb-0">{option.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className="form-group d-flex gap-3 gap-sm-5 align-items-self-end pt-3">
                        <label className="me-3">Age</label>
                        <input
                          type="text"
                          className="input-account"
                          placeholder="05-06-1997"
                          value={editDetails.dob}
                          disabled
                        ></input>
                      </div> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="f20-size mb-0">Interests</h3>
                      <img onClick={handleEditInterestsModal} style={{ cursor: "pointer" }} src={IMAGES.EDIT_ICON} title="edit" alt="edit" className="interst-edit" />
                    </div>
                    <div className="interest-head">
                      {renderInterests()}
                    </div>
                    <div className="mt-3">

                      {isLoading ?
                        <div className="loader-style">
                          <Spinner />
                        </div> :
                        <button type="button" onClick={handleUpdate} className="gradient-btn border-0 w-100 mt-4">Done</button>}
                    </div>

                  </form>
                </div>
              </> :
                <FourthStep
                  setFormData={setFormData}
                  setEditInterestsModal={setEditInterestsModal}
                  formData={formData}
                  setEditDetails={setEditDetails}
                  editDetails={editDetails}
                  interests={interests}
                  setInterests={setInterests}
                  catIds={catIds}
                  owninterest={owninterest}
                />
              }

              {
                changeImage == true && (
                  <ProfileImage
                    handleFileUpload={handleFileUpload}
                    setChangeImage={setChangeImage}
                    handleClose={handleClose}
                    setIsImageUploaded={setIsImageUploaded}
                    isImageUploaded={isImageUploaded}
                    editDetails={editDetails}
                    setEditDetails={setEditDetails}
                  />
                )

              }
            </div>
        }

      </div>
    </div>
  );
};
export default MyAccount;
