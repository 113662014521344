// SidebarContext.js
import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAutoRollOn, setIsAutoRollOn] = useState(false);
  const [channelForChat, setChannelForChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLodaing] = useState(false);
  const [isLoadingMain, setIsLodaingMain] = useState(true);
  const [callStartTime, setCallStartTime] = useState(null);
  const [callEndTime, setCallEndTime] = useState(null);
  const [callDuration, setCallDuration] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [callStartOpen, setCallStartOpen] = useState(false);
  const [callStartOpenCall, setCallStartOpenCall] = useState(false);
  const [videoButtonOpen, setVideoButtonOpen] = useState(false)
  const [receiveCall, setReceiveCall] = useState(false)
  const [endedChatModalFriend, setEndedChatModalFriend] = useState(false);

  return (
    <>
      <SidebarContext.Provider value={{
        isSidebarVisible, setIsSidebarVisible,
        isAutoRollOn, setIsAutoRollOn,
        channelForChat, setChannelForChat,
        messages, setMessages,
        isLoading, setIsLodaing,
        setIsLodaingMain, isLoadingMain,
        callStartTime, setCallStartTime,
        callEndTime, setCallEndTime,
        callDuration, setCallDuration,
        setCallStartOpen, callStartOpen,
        userDetail, setUserDetail,
        videoButtonOpen, setVideoButtonOpen, receiveCall, setReceiveCall,
        callStartOpenCall, setCallStartOpenCall,setEndedChatModalFriend,endedChatModalFriend
      }}>
        {children}
      </SidebarContext.Provider>
    </>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
