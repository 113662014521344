import React, { useEffect, useState } from 'react';
import { IMAGEURL } from '../../config';
import { useSidebarContext } from '../../context/sidebarContext';

const FriendCallEndModal = () => {
    const {
        callStartTime, setCallStartTime,
        callEndTime, setCallEndTime,
        callDuration, setCallDuration, setEndedChatModalFriend, receiveCall, setReceiveCall,endedChatModalRemove, setEndedChatModalRemove,setIsSidebarVisible
    } = useSidebarContext();

    const friend_Call = JSON.parse(localStorage.getItem("activeChatUser"));
    const other_response = receiveCall && JSON.parse(localStorage.getItem("otherResponse"));
    const [time, setTime] = useState(3);
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
        if (callStartTime && callEndTime) {
            const duration = callEndTime.getTime() - callStartTime.getTime();
            setCallDuration(duration);
        }
    }, [callStartTime, callEndTime, setCallDuration]);

    useEffect(() => {
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime === 1) {
                        setIsRunning(false);
                        setEndedChatModalFriend(false);
                        setReceiveCall(false);
                        localStorage.removeItem("otherResponse");
                        localStorage.removeItem("activeChatUser");
                        localStorage.removeItem("startTime")
                        localStorage.removeItem("callOption")

                    }
                    return prevTime - 1;
                });
            }, 2000);   
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [isRunning, setEndedChatModalFriend, setReceiveCall]);

    const formatCallDuration = (duration) => {
        const totalSeconds = Math.floor(duration / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':');
        return formattedTime;
    };

    return (
        <div className="myaccount-gallery modal-gallery">
            <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered">
                <div className="myaccount-content content-gallery bg-gradient-modal">
                    <div className="border-0 position-relative text-center">
                        <div className='enduser-img mb-3'>
                            <img src={receiveCall ? IMAGEURL + other_response?.avatar : IMAGEURL + friend_Call?.avatar} alt="user-image" className='text-center mb-4 new-user' />
                        </div>
                        <h4 className="text-center mb-0 fw-500 text-white">
                        {receiveCall ? other_response?.username : friend_Call?.username} has ended the chat
                        </h4>
                    </div>
                    <div className="modal-body p-0 pt-2">
                        {/* <div className='pt-0'>
                            <h4 className='text-center text-white mb-3'></h4>
                        </div> */}
                        <p className='text-center text-white mb-0'>{formatCallDuration(callDuration)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FriendCallEndModal;
