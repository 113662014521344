import axios from "axios";
import { BASEURL } from "../config";
import { ROUTES } from "../routes/routes";

const axiosInstance = axios.create({
  baseURL: BASEURL,
  headers: {
    "Content-Type": 'multipart/form-data',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

export const handleRequest = async (method, url, data = null, isMultipart = false) => {
  try {
    const headers = isMultipart ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' };
    const response = await axiosInstance.request({
      method,
      url,
      data,
      headers
    });
    return response;
  } catch (err) {
    if (err.response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    } else if (err.response.status === 409) {
      localStorage.clear();
      window.location.replace(ROUTES.SIGNIN);
      // const message = "Session expired. This account is being used on another platform."
      // localStorage.setItem("message",message)
    }
    console.error(`${method} Error:`, err);
    throw err;
  }
};
