import React, { useState } from 'react'
import Webcam from "react-webcam";
import { IMAGES } from '../../../../components/images';
import { handleRequest } from '../../../../services';
import { UPLOADIMAGE } from '../../../../config';
import Spinner from '../../../../components/loader/loader';

const WebCam = ({ setCamOn, setViewCamImage, setOnCamContainer, setCaptureImage, setFormData, formData, setIsImageUploaded, setSelectedAvatarIndex, setViewGalleryImage }) => {

  const webcamRef = React.useRef(null);
  const [viewScr, setViewScr] = useState(null);
  const [loader, setLoader] = useState(false);
  function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
  function urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }
  async function uploadImage(b64img) {

    var file = await urltoFile(b64img, 'name.png', base64MimeType(b64img));
    // if(setCaptureImage){
    //   setCaptureImage(file);

    // }



    await handleFileUploadByCamra(file);

  }

  const handleFileUploadByCamra = async (file) => {
    const formDataImg = new FormData();
    formDataImg.append("image", file);
    try {
      const response = await handleRequest("POST", UPLOADIMAGE, formDataImg, true);
      if (response.code === 200) {
        setFormData({
          ...formData,
          avatar: response.body
        });
        localStorage.setItem("fileUpload", 2)
        setIsImageUploaded(true);
        setSelectedAvatarIndex(null);
        setViewGalleryImage(false);
        // setLoader(false);
        setCamOn(false);
        setViewCamImage(true);
        // if(setOnCamContainer){
        //   setOnCamContainer(false);
        // }
        setCamOn(false);

      } else {
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setLoader(true);
    await uploadImage(imageSrc);
  }

  const handleClose = () => {
    setCamOn(false);
  }

  return (
    <>
      {
        loader ? (<div className="loader-style">
          <Spinner />
        </div>) :
          <div className="myaccount-gallery modal-gallery">
            <div className='d-flex justify-content-center align-items-center mx-auto myaccount-dialog-centered'>
              <div className="myaccount-content content-gallery">
                <div className=" border-0 position-relative text-center">
                  <h3>Camera</h3>
                  <button type="button" className="border-0 bg-none close-btn">
                    <img src={IMAGES.CLOSSBUTTON} onClick={handleClose} />
                  </button>
                </div>
                <div className="myaccount-body pt-2">
                  <Webcam
                    audio={false}
                    height={100}
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    width={100}
                    mirrored={true}
                    screenshotQuality={0.98}
                    className='profile-camera'
                  />
                  <button type='button' className="gradient-btn border-0 w-100 mt-3" onClick={capture}>Capture photo</button>
                  {/* <div>
                <img  width="400" height="400" src={viewScr} alt="not cature" />
              </div> */}
                </div>
              </div>
            </div>
          </div>
      }

    </>

  );
}

export default WebCam;