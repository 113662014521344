import React, { useState } from "react";
import { IMAGES } from "../../components/images";
import { IMAGEURL } from "../../config";

const MatchedInterestModal = (props) => {
  const { setIsInterestModalOpen, interestArray, otherUser, check } = props
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(IMAGEURL + userData?.userdata?.avatar)
  const avatar= userData?.userdata?.avatar ? userData?.userdata.avatar :userData.avatar 
  return (
    <div>
      <div className="myaccount-gallery  modal-gallery">
        <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered ">
          <div className="myaccount-content content-gallery">
            <div className=" border-0 position-relative">

            </div>
            <div className="modal-body p-0 pt-2">
              <div >
                {
                  check == "call" ? < div className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <img src={otherUser.avatar ? IMAGEURL + otherUser.avatar : IMAGES.USER_ICON} className="matchedinterestprofile mx-auto mb-3" />
                      <p className="f24-size fw-600 text-color mb-3">{interestArray?.length} Interest Matched</p>
                    </div>
                  </div> :
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="text-center">
                        <img src={IMAGEURL + avatar} className="matchedinterestprofile mx-auto mb-3" />
                        <p className="f24-size fw-600 text-color mb-3">{interestArray?.length} Interest Matched</p>
                      </div>
                    </div>
                }
                {
                  check == "call" ? <div className="matched-interests p-2 mb-3">
                    <div className="d-flex flex-wrap justify-content-between gap-4">
                      {
                        interestArray?.map((interest) => (
                          <button className="matched-interest-list">{interest}</button>
                        ))
                      }

                    </div>
                  </div> :
                    <>
                      {
                        interestArray?.length > 0 ? <div className="matched-interests p-2 mb-3">
                          <div className="d-flex flex-wrap justify-content-between gap-4">
                            {
                              interestArray?.map((interest) => (
                                <button className="matched-interest-list">{interest}</button>
                              ))
                            }

                          </div>
                        </div> :
                          <p className="text-center py-4 " style={{ fontSize: 20 }}>No interest matched yet.</p>

                      }
                    </>
                }


                <div className="d-flex justify-content-center align-items-center">
                  <button className="gradient-btn  border-0" onClick={() => setIsInterestModalOpen(false)}>Okay</button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default MatchedInterestModal;
