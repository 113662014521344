import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const NoData = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    return (
        <>
            {
                token ?
                    <div className="text-center py-4 h-100 d-flex justify-content-center align-items-center flex-column">
                        < h1 > 404! Not Found</h1 >
                        <button className="gradient-btn border-0  mx-auto mt-3" onClick={() => navigate(ROUTES.HOME)}>
                            Return to Home
                        </button>
                    </div >
                    :
                    <div className="bg-white w-100 box-shadow login-radius">

                        <div className="text-center py-4">
                            <h1>404 ! Not Found</h1>
                            <button className="gradient-btn border-0  mx-auto mt-5" onClick={() => navigate(ROUTES.SIGNIN)}>
                                Return to Login
                            </button>
                        </div>
                    </div>
            }
        </>

    )
};
export default NoData;