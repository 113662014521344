import React, { useState } from "react";
import Tabs from "./components/tabs";
import Online from "./components/online";
import AllFriends from "./components/allFriends";
import SentRequest from "./components/sentRequest";
import NewRequest from "./components/newRequest";
import Blocked from "./components/blocked"
import { useSidebarContext } from "../../../context/sidebarContext";

const tabNames = ["Online", "All Friends", "Sent Request", "New Request", "Blocked"];

const Friends = () => {
  const {isSidebarVisible} = useSidebarContext()
  const [activeTab, setActiveTab] = useState(0);
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <Online />;
      case 1:
        return <AllFriends />;
      case 2:
        return <SentRequest />;
      case 3:
        return <NewRequest />;
      case 4:
        return <Blocked />;
      default:
        return null;
    }
  };

  return (
    <div className={`content-inner ${!isSidebarVisible&&"hamburger-space"}`}>
      <h3>Friends</h3>
      <Tabs tabNames={tabNames} activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderTabContent()}
    </div>
  );
};

export default Friends;
