import React, { useState } from "react";
import { IMAGES } from "../../components/images";
import { handleRequest } from "../../services/index";
import { IMAGEURL, UPLOADIMAGE } from "../../config";
import { Link } from "react-router-dom";
import WebCam from "../auth/signup/components/webCam";
import Spinner from "../../components/loader/loader";

const SetProfilePic = (props) => {
  const {
    setUploadImageModal,
    setFormData,
    formData,
    setIsImageUploaded,
    isImageUploaded,
    setSelectedAvatarIndex,
    error,
    setError,
    viewCamImage, setViewCamImage, viewGalleryImage, setViewGalleryImage,
    setOnCamContainer,
    setCaptureImage
  } = props;

  const [camOn, setCamOn] = useState(false);
  const [loader, setLoader] = useState(false);
  const file_upload = localStorage.getItem("fileUpload")

  const handleClose = () => {
    setUploadImageModal(false);
  };
  const handleFileChange = (event) => {
    // debugger;
    // if(setCaptureImage){
    //   setCaptureImage(event.target.files[0]);
    //   setOnCamContainer(false);
    // }

    handleFileUpload(event.target.files[0]);
  };


  const handleFileUpload = async (file) => {
    const formDataImg = new FormData();
    formDataImg.append("image", file);
    try {
      setLoader(true);
      const response = await handleRequest("POST", UPLOADIMAGE, formDataImg, true);
      console.log(response);
      if (response?.code == 200) {
        console.log(formData, "form apiiiiiiiiiiiiiiiii")
        setFormData({ ...formData, avatar: response.body })
        setIsImageUploaded(true);
        setSelectedAvatarIndex(null);
        setViewCamImage(false);
        setViewGalleryImage(true);
        localStorage.setItem("fileUpload", 3)
        setLoader(false);
        // setUploadImageModal(false);
        if (error) {
          setError(null);
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  function captureImageHandler() {
    setCamOn(true);
  }
  console.log(isImageUploaded, viewCamImage, " isImageUploaded && viewCamImage")
  return (
    <>
      {camOn ? (<div ><WebCam
        setViewCamImage={setViewCamImage}
        setCamOn={setCamOn}
        setCaptureImage={setCaptureImage}
        setOnCamContainer={setOnCamContainer}
        setFormData={setFormData}
        setIsImageUploaded={setIsImageUploaded}
        setSelectedAvatarIndex={setSelectedAvatarIndex}
        setViewGalleryImage={setViewGalleryImage}
        formData={formData}
      />
      </div>) : (
        <div
          className="myaccount-gallery modal-gallery"
          id="avatarmodal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
        >

          <div className="myaccount-dialog myaccount-dialog-centered ">
            {loader ? (<div className="loader-style">
              <Spinner />
            </div>) : (<div className="myaccount-content content-gallery">
              <div className=" border-0 position-relative">
                <h3 className="text-center mb-0 fw-500 text-color">
                Set Profile Picture
                                </h3>
                <button
                  type="button"
                  onClick={handleClose}
                  className="border-0 bg-none close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={IMAGES.CLOSSBUTTON} />
                </button>
              </div>
              <div className="modal-body px-0 top-space">
                <div className="row">
                  <div className="col-6 text-center">
                    <div className="profile-box ">
                      <div className="profile-inner active galleryimg-upload position-relative" style={{ cursor: "pointer" }}>
                        <img
                          id="blah"
                          src={file_upload == 2
                            ? IMAGEURL + formData.avatar : IMAGES.CAMERAMODAL}
                          alt="profile"
                          className="myaccountprofile"
                          onClick={captureImageHandler}
                        />
                      </div>
                      <p className="text-center pt-1 fw-400 f24-size">Camera</p>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="profile-box " style={{ cursor: "pointer" }}>
                      <div className="profile-inner active galleryimg-upload position-relative">
                        <img
                          id="blah"
                          src={isImageUploaded ? (viewGalleryImage ? IMAGEURL + formData.avatar : IMAGES.GALLERY) : IMAGES.GALLERY}
                          alt="profile"
                          className={`myaccountprofile ${isImageUploaded ? "profilecamupload" : ""}`}
                        />
                        <input
                          accept=".jpg, .jpeg, .png"
                           type="file"
                          id="imgInp"
                          className="w-100 inputupload"
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="text-center pt-1 fw-400 f24-size">Gallery</p>
                    </div>
                  </div>
                </div>
                <div className="top-space">
                  <Link
                    onClick={() => setUploadImageModal(false)}
                    className="gradient-btn border-0 w-100"
                  >
                    Done
                  </Link>
                </div>
              </div>
            </div>)}

          </div>
        </div>)}

    </>

  );
};

export default SetProfilePic;
