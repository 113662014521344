import React, { useEffect, useState } from 'react'
import { IMAGES } from '../../../../components/images'
import { ACCEPT_REJECT, FRIEND_UNFRIEND, GET_ALL_CONNECTION, IMAGEURL } from '../../../../config';
import { handleRequest } from '../../../../services';
import Spinner from '../../../../components/loader/loader';

const SentRequest = () => {
  const [sentFriendRequestList, setSentFriendRequestList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getSentFriendRequestList = async () => {
    setIsLoading(true)
    try {
      const response = await handleRequest("GET", `${GET_ALL_CONNECTION}?type=2`);
      setSentFriendRequestList(response.body)

    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }
  const handleCancelRequest = async (id, status) => {
    const data = {
      "sender_id": id,
      "status": status
    }
    try {
      const response = await handleRequest("GET", FRIEND_UNFRIEND + id);
      getSentFriendRequestList()
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getSentFriendRequestList()
  }, [])

  return (
    <>
      {isLoading ? <div className="web-cam inner-spinner"><Spinner /></div> : <div className="tabs-inner-content mt-4">
        <div className="scroll-inner">
          <div className="row mt-4">
            {sentFriendRequestList !== null && sentFriendRequestList?.filter(item => item.username !== null)?.length > 0 ? sentFriendRequestList?.filter(item => item.username !== null)?.map((request) =>
            (<div className=" col-lg-6 col-xl-4 col-xxl-4 mb-4">
              <div className="sendrequest-box">
                <div className="profile-online position-relative"><img src={request.avatar ? IMAGEURL + request?.avatar: IMAGES.USER_ICON} />
                {
                  request.is_online == 1 &&  <span className="green-dot">
                  </span>
                }
                 
                </div>
                <p className="mb-0 online-text text-color fw-500 one-line">{request?.username}</p>
                <button onClick={() => handleCancelRequest(request?.friend_id, 1)} type="button" className="cancel-btn f14-size fw-500 text-color    ">Cancel</button>
              </div>
            </div>)
            ) :
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <h4>No sent request yet</h4>
              </div>

            }
          </div>
        </div>
      </div>}
    </>
  )
}

export default SentRequest