import React, { useEffect, useState, useRef } from "react";
import { IMAGES } from "../../../components/images";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import Spinner from "../../../components/loader/loader";

const AuthLayout = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0,0)
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            {isLoading && (
                <div className="loader-style loader-background" style={{ height: "100vh" }}>
                    <Spinner />
                </div>
            )}
            <div
                className={`content-wrapper ${isLoading ? 'hidden' : 'visible'}`}
            >
                <section
                    className="new-bg-wrapper py-40"
                >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <img
                                    src={IMAGES.ASOCIAL_WHITE}
                                    alt="Asocial white icon"
                                    className="header-white-logo"
                                    onClick={() => navigate(ROUTES.LANDINGPAGE)}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                            <div className="col-12 mx-auto">
                                <div className="new-common-wrapper common-wrapper">
                                        {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AuthLayout;
