import React from 'react';
import PuffLoader   from "react-spinners/PuffLoader";

const Spinner = () => {

  return (
    <PuffLoader  
      color="#FD4E86"
      size={90}
      aria-label="Loading Spinner"
      data-testid="loader"
      className="spinner"

    />
  );
}

export default Spinner;
