import React, { useEffect, useState } from 'react'
import { IMAGES } from '../../../../components/images'
import { BLOCK_USER, GET_BLOCKED_USERS, IMAGEURL } from '../../../../config';
import { handleRequest } from '../../../../services';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/loader/loader';

const Blocked = () => {
  const [blockedUserList, setBlockedUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const getBlockedUserList = async () => {
    setIsLoading(true);

    try {
      const response = await handleRequest("GET", GET_BLOCKED_USERS);
      setBlockedUserList(response.body)

    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);

  }

  const handleBlockUser = async (id) => {

    try {
      const response = await handleRequest("GET", `${BLOCK_USER}?block_user_id=${id}`);
      console.log(response, "Report User....");
      toast.success(response.message)
      getBlockedUserList();
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBlockedUserList();
  }, [])

  return (
    <>
    {isLoading ? <div className="web-cam inner-spinner"><Spinner /></div> :
      <div className="tabs-inner-content mt-4">
        <h4 className="text-color">Blocked ({blockedUserList?.length})</h4>
        <div className="scroll-inner">
          <div className="row mt-4">
            {blockedUserList !== null && blockedUserList?.filter(blockedUser => blockedUser?.user !== null)?.length > 0 ? (
              blockedUserList
                ?.filter(blockedUser => blockedUser?.user !== null)
                .map((blockedUser) => (
                  <div className="col-lg-6 col-xl-4 col-xxl-4 mb-4" key={blockedUser.block_user_id}>
                    <div className="sendrequest-box">
                      <div className="profile-online position-relative">
                        <img src={blockedUser?.user.avatar ? IMAGEURL + blockedUser?.user?.avatar : IMAGES.USER_ICON} alt="User Avatar" />
                        {
                          blockedUser?.is_online == 1 && <span className="green-dot"></span>

                        }
                      </div>
                      <p className="mb-0 online-text text-color fw-500 one-line">{blockedUser?.user?.username}</p>
                      <button onClick={() => handleBlockUser(blockedUser?.block_user_id)} type="button" className="cancel-btn f14-size fw-500 text-color">Unblock</button>
                    </div>
                  </div>
                ))
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <h4>No blocked yet</h4>
              </div>
            )}
          </div>
        </div>
      </div>}
      </>
  );

}

      export default Blocked