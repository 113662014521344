import React, { useRef, useState } from "react";
import { IMAGES } from "../../../../components/images";
import OtpInput from "react-otp-input";
import { MAKE_USER_ONLINE, OTP_VERIFICATION, RESEND_OTP } from "../../../../config";
import { handleRequest } from "../../../../services";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import Spinner from "../../../../components/loader/loader";
import SignUpLayout from "../../signup";

const Otp = (props) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoaing, setIsLoading] = useState(false);
  const { phone, userData } = props;
  const navigate = useNavigate();
  const[check,setCheck] = useState(false)
  const handleValidation = () => {
    if (!otp.trim()) {
      setError("Please enter the verification code.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    const data = {
      mobile_no: `+${phone}`,
      otp: otp,
    };
    if (handleValidation()) {

      try {
        const response = await handleRequest("POST", OTP_VERIFICATION, data);
        console.log(response);
        if (response.code === 200) {
          if (userData.is_profile_complete !== 0 && userData.is_profile_complete !== "0") {
            const userData = JSON.parse(localStorage.getItem("userData"))
            localStorage.setItem("token", JSON.stringify(userData.token));
            navigate(ROUTES.HOME);
            // getMakeUserOnline()
          } else {
            // navigate(ROUTES.SIGNUP);
            setCheck(true)
          }
        } else if (response.code === 400) {
          setError(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleResendCode = async () => {
    const data = {
      mobile_no: `+${phone}`
    }
    try {
      const response = await handleRequest("POST", RESEND_OTP, data);
      console.log(response);
      if (response.code === 200) {
        alert('Verification Code has been sent to your phone');
      } else if (response.code === 400) {
        setError(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // const getMakeUserOnline = async () => {
  //   try {
  //     const response = await handleRequest("GET", MAKE_USER_ONLINE)
  //     console.log(response, "responseeeeeeeeeee")
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  return (
    <>
    {
      check ? <SignUpLayout/> :
      <div className="otp-headers">
      <div className="bg-white w-100 box-shadow login-radius">
      <div className="heading">
        <h3 className="">Verification Code</h3>
        <p className="fw-400 mb-0">
          Please enter verification code sent to your phone.
        </p>
      </div>
      {/* <div className="text-center padding-top-4 margin-top-4">
        <img src={IMAGES.VERIFY} className="img-fluid" />
      </div> */}
      <div className="top-space">
        <form onSubmit={handleSubmit}>
          <div className="form-group d-flex align-items-center gap-2 gap-sm-3 justify-content-between otp-form flex-column">
            <OtpInput
              value={otp}
              onChange={setOtp}
              inputStyle="verify-field"
              numInputs={6}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
          <div className="code-cus d-flex align-items-center justify-content-center margin-top-4 mb-1">
            {/* <p className="fw-400 mb-0">Didn't received code?</p> */}
            <Link  onClick={handleResendCode} className="sec-theme text-decoration-underline d-flex align-items-center justify-content-between ">Resend</Link>
          </div>
          <div className="upper-space">
            {isLoaing ?
              <div className="loader-style">
                <Spinner />
              </div> : <button type="submit" className="gradient-btn border-0 w-100">
                Submit
              </button>}
          </div>
        </form>
      </div>
      </div>
    </div>
    }
   

    </>
  );
};

export default Otp;
