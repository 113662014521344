import React, { useEffect, useState } from "react";
import Sidebar from "./components/sidebar/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyAccount from "../profile/myAccount";
import { handleRequest } from "../../services";
import { GET_MY_DETAIL, SOCKETURL } from "../../config";
import { useSidebarContext } from "../../context/sidebarContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMAGES } from "../../components/images";
import UnLockFeatures from "../modals/unLockFeatures";
import Spinner from "../../components/loader/loader";
import io from 'socket.io-client';
import { ROUTES } from "../../routes/routes";
import FriendCallEndModal from "../modals/friendCallEnd";

const Layout = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const user_id = userData?.userdata?.id ? userData?.userdata?.id : userData?.id;
  const location = useLocation();
  const path = location.pathname;
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [myAccountModal, setMyAccountModal] = useState(false);
  const [showUnlockFeaturesModal, setShowUnlockFeaturesModal] = useState(false);
  const { isSidebarVisible, setIsSidebarVisible, receiveCall, setReceiveCall, userDetail, setUserDetail, callStartOpen, setIsLodaingMain, isLoadingMain, setVideoButtonOpen, endedChatModalFriend } = useSidebarContext();
  const otherFriendAccountType = localStorage.getItem("otherFriendAccountType");
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem("flag")
    const socket = io.connect(`${SOCKETURL}?userId=${user_id}`);
    const handleOnline = () => {
      if (!socket.connected) {
        socket.connect();
        console.log("Reconnected to WebSocket server");
      }
    };

    const handleOffline = () => {
      socket.disconnect();
      console.log("Disconnected from WebSocket server due to no internet connection");
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      socket.disconnect();
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      console.log('Disconnected from WebSocket server');
    };
  }, [user_id]);

  useEffect(() => {
    const socket = io(SOCKETURL);
    const handleReturnLiveChannelList = async (response) => {
      console.log(response, "responseresponseresponse")
      setReceiveCall(true)
      console.log(location, "path")
      navigate(ROUTES.VIDEO_CALL, { state: { programmatic: true } });
      localStorage.setItem("otherResponse", JSON.stringify(response))
    };
    socket.on(`generateRTCToken${user_id}`, handleReturnLiveChannelList);

    return () => {
      socket.off(`generateRTCToken${user_id}`, handleReturnLiveChannelList);
      setReceiveCall(false)

    };
  }, [user_id]);

  const fetchDetails = async () => {
    setIsLodaingMain(true);
    try {
      const response = await handleRequest("GET", GET_MY_DETAIL);
      console.log(response, "response")
      localStorage.setItem("interest_matching", response.body.interest_matching);
      setUserDetail(response?.body);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    } finally {
      setIsLodaingMain(false);
    }
  };

  const handleSidebarOpen = () => {
    setIsSidebarVisible(true);
  };

  const handleUnloackFeaturesModal = () => {
    setShowUnlockFeaturesModal(true);
  };

  useEffect(() => {
    setIsLodaingMain(true);
    fetchDetails();
    if (userDetail?.account_type == 0) {
      setIsSidebarVisible(false);
    }
    if (window.innerWidth <= 991) {
      setIsSidebarVisible(false);
    }
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setIsSidebarVisible(false);
      } else {
        handleSidebarOpen();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userDetail?.account_type == 0) {
      setIsSidebarVisible(false);
    }
  }, [userDetail]);

  const handleClick = () => {
    if (!callStartOpen && window.innerWidth <= 820) {
      setVideoButtonOpen(true);
    }
  };

  useEffect(() => {
    if (location.pathname != "/video_call") {
      localStorage.setItem("lastPathname", location.pathname);
    }
  }, [location.pathname]);



  return (
    <>
      <div>
        {isLoadingMain ? (
          <div className="web-cam freeuser-spinner">
            <Spinner />
          </div>
        ) : (
          <div className={`main-wrapper ${path == "/home" && "custom-overflow"} ${path == "/video_call" && "friend-main"}`}>
            {userDetail?.account_type == 1 && !callStartOpen && (
              <Sidebar
                path={path}
                sidebarOpen={sidebarOpen}
                fetchDetails={fetchDetails}
                setSidebarOpen={setSidebarOpen}
                myAccountModal={myAccountModal}
                setMyAccountModal={setMyAccountModal}
              />
            )}
            <div
              className={`main-content ${userDetail?.account_type == 0 && "main-content-close content-open"} ${!sidebarOpen && "content-open"} ${!isSidebarVisible && "main-content-close"} ${callStartOpen && "sidebar-close"}`}
            >
              {(userDetail?.account_type == 1 && !isSidebarVisible && !callStartOpen) && (
                <div className="hamburger-icon" onClick={() => handleClick()}>
                  <Link onClick={handleSidebarOpen} style={{ cursor: "pointer" }} className="hamburger">
                    <img
                      onClick={handleSidebarOpen}
                      style={{ cursor: "pointer" }}
                      src={IMAGES.HAMBURGER}
                      alt="HAMBURGER-ICON"
                    />
                  </Link>
                </div>
              )}
              {userDetail?.account_type == 0 && otherFriendAccountType == 0 && (
                <div className="hamburger-icon d-flex justify-content-start align-items-center gap-3 w-100">
                  <Link
                    onClick={handleUnloackFeaturesModal}
                    style={{ cursor: "pointer" }}
                    className="hamburger"
                  >
                    <img
                      onClick={handleSidebarOpen}
                      style={{ cursor: "pointer", border: "2px solid #f75796", borderRadius: "100%" }}
                      src={IMAGES.USER_ICON}
                      alt="HAMBURGER-ICON"
                    />
                  </Link>
                  <p className="mb-0">Asocialite</p>
                </div>
              )}
              {children}
            </div>
          </div>
        )}
      </div>
      {myAccountModal && <MyAccount setMyAccountModal={setMyAccountModal} userDetail={userDetail} fetchDetails={fetchDetails} toast={toast} />}
      {showUnlockFeaturesModal && <UnLockFeatures setShowUnlockFeaturesModal={setShowUnlockFeaturesModal} />}
      <ToastContainer theme="colored" />
      {(endedChatModalFriend
      ) && <FriendCallEndModal />}
    </>
  );
};

export default Layout;
