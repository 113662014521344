import React, { useEffect, useState } from "react";
import Chat from "./components/chat";
import ChatList from "./components/chatList";
import { useLocation } from "react-router-dom";
import { CHAT_USER_HISTORY, GET_MY_DETAIL } from "../../../config";
import { handleRequest } from "../../../services";
import { IMAGES } from "../../../components/images";
import { useSidebarContext } from "../../../context/sidebarContext";
import Spinner from "../../../components/loader/loader";

const Messages = () => {
  const location = useLocation();
  const [activeChatUser, setActiveChatUser] = useState(location?.state?.friend)
  const [me, setMe] = useState(null);
  const [userChatHistoryList, setUserChatHistoryList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isSidebarVisible } = useSidebarContext();
  const [zIndex, setZIndex] = useState("chatList");
 
  const chatUserHistory = async () => {
    setIsLoading(true)
    try {
      const response = await handleRequest("GET", CHAT_USER_HISTORY)
      const array = response.body;
      setUserChatHistoryList(response.body)
      console.log(response, "user")
      // if(!location?.state){
      //   setActiveChatUser({
      //     id: response?.body[0]?.lastMessage?.id,
      //     friend_id: response?.body[0]?.receiver?.id,
      //     username:response?.body[0]?.receiver?.username,
      //     avatar: response?.body[0]?.receiver?.avatar,
      //     chatroomId:response?.body[0]?.lastMessage?.chat_id,
      //     created_at:response?.body[0]?.lastMessage?.created_at
      //   });
      // } 
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const getMyDetail = async () => {
    const response = await handleRequest("GET", GET_MY_DETAIL)
    setMe(response.body);
  }
  useEffect(() => {
    chatUserHistory();
    getMyDetail()
    return () => {
      localStorage.setItem("messageNavigate", 1)
    }
  }, [])
  return (
    <>
      <div className={`chat-design ${!isSidebarVisible && "hamburger-space"}`}>
        <h3>Messages</h3>
        {isLoading && userChatHistoryList.length == 0 ? (
          <div className="web-cam inner-spinner outer-spinner">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="message-area mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="chat-area text-center">
                    {userChatHistoryList.length > 0 || activeChatUser ? (
                      <>
                        <ChatList
                          setZIndex={setZIndex}
                          activeChatUser={activeChatUser}
                          zIndex={zIndex}
                          isLoading={isLoading}
                          setActiveChatUser={setActiveChatUser}
                          userChatHistoryList={userChatHistoryList}
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          setUserChatHistoryList={setUserChatHistoryList}
                        

                        />
                        <Chat
                          setZIndex={setZIndex}
                          zIndex={zIndex}
                          activeChatUser={activeChatUser}
                          setActiveChatUser={setActiveChatUser}
                          chatUserHistory={chatUserHistory}
                          me={me}
                         
                        />
                      </>
                    ) : (
                      !isLoading && (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ height: "80vh" }}
                        >
                          <h4>No chat yet</h4>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>

  );
};

export default Messages;
