import React, { useEffect, useState } from 'react'
import { IMAGES } from '../../components/images'
import { FRIEND_UNFRIEND, GET_MATCHED_INTEREST_STATUS, IMAGEURL, REPORT_USER } from '../../config';
import { handleRequest } from '../../services';
import { useSidebarContext } from '../../context/sidebarContext';
const EndedChatModal = (props) => {
  const { setEndedChatModal, otherUser, toast, isAutoRollOn, setIsAutoRollOn } = props;
  const {
    callStartTime, setCallStartTime,
    callEndTime, setCallEndTime,
    callDuration, setCallDuration
  } = useSidebarContext();

  const [time, setTime] = useState(3);
  const [isRunning, setIsRunning] = useState(true);
  const interest_matching = localStorage.getItem("interest_matching")
  const handleAddFriend = async () => {
    try {
      const response = await handleRequest("GET", FRIEND_UNFRIEND + otherUser.id);
      toast.info(response.message);
      setEndedChatModal(false);
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }
  const handleReportUser = async () => {
    const data = {
      blocked_user_id: otherUser.id
    }
    try {
      const response = await handleRequest("POST", REPORT_USER, data);
      setEndedChatModal(false);
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let timer;
    if (callStartTime && callEndTime) {
      const duration = callEndTime.getTime() - callStartTime.getTime();
      console.log(duration)
      setCallDuration(duration);
    }
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 1) {
            setIsRunning(false);
            setEndedChatModal(false)


          }
          return prevTime - 1;
        });
      }, 3000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isRunning])

  const formatCallDuration = (duration) => {
    // Convert milliseconds to seconds
    const totalSeconds = Math.floor(duration / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':');
    return formattedTime;
  };

  const handleSwitchOnOff = () => {
    localStorage.setItem("auto", 0)
    if (isAutoRollOn === false) {
      setIsAutoRollOn(true)
    } else {
      setIsAutoRollOn(false)
    }
  }


  return (
    <div className="myaccount-gallery  modal-gallery">
      <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered  ">
        <div className="myaccount-content content-gallery bg-gradient-modal">
          <div className=" border-0 position-relative text-center">
            <div className='enduser-img mb-3'>
              <img src={otherUser?.avatar ? IMAGEURL + otherUser?.avatar : IMAGES.USER_ICON} alt="user-image" className='text-center mb-4 new-user' />
            </div>
            <h4 className="text-center mb-0 fw-500 text-white">
              User has ended the chat
            </h4>
            <p></p>
            {/* <button
            onClick={()=>setEndedChatModal(false)}
              type="button"
              className="border-0 bg-none close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={IMAGES.CLOSSBUTTON} />
            </button> */}
          </div>
          <div className="modal-body p-0">
            <div className='pt-0'>
              {/* <h3 className='text-center'>{otherUser?.username}</h3> */}
              <h4 className='text-center text-white mb-3'>{otherUser?.username}</h4>
              {/* {
                isAutoRollOn == true && 
 */}
              <div className="cancel-toggle mb-2">
                <p className="auto-text fw-600 mb-0 text-white">Autoroll</p>
                <div className="on-off-toggle" style={{ zIndex: "2222" }}>
                  <input
                    className="on-off-toggle__input"
                    type="checkbox"
                    id="bopis"
                    checked={isAutoRollOn}
                    onChange={handleSwitchOnOff}
                  />
                  <label for="bopis" className="on-off-toggle__slider"></label>
                </div>
              </div>

              {/* } */}
            </div>
            <p className='text-center text-white mb-0'>{formatCallDuration(callDuration)}</p>
            {/* <button style={{cursor:'pointer'}}
                type='button'
                onClick={handleSwitchOnOff}
                className="gradient-btn border-0 mx-auto mt-3 end-call-btns cancel-autoroll"
              >
                Cancel Autoroll
              </button> */}


            {/* <form>
              <div className="d-flex justify-content-between align-items-center gap-5">
                <button
                type='button'
                onClick={handleAddFriend}
                  className="gradient-btn border-0 w-100 end-call-btns"
                >
                  Add Friiend
                </button>
                <button
                type='button'
                onClick={handleReportUser}
                  className="gradient-btn border-0 w-100 end-call-btns"
                >
                  Report
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div >
  )
}

export default EndedChatModal