import React, { useEffect, useMemo, useState } from "react";
import { IMAGES } from "../../../../components/images";
import { handleRequest } from "../../../../services";
import { CHAT_USER_HISTORY, IMAGEURL } from "../../../../config";
import { formatDate, extractTime, formatTimeToAmPm } from '../../../../utils';
import { useLocation } from "react-router-dom";


const ChatList = (props) => {
  const { setActiveChatUser, userChatHistoryList, searchQuery, setSearchQuery, setUserChatHistoryList, isLoading, setZIndex, zIndex, activeChatUser,
  } = props;

  const location = useLocation();

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const safeUserChatHistoryList = Array.isArray(userChatHistoryList) ? userChatHistoryList : [];

  const filterUserList = useMemo(() => {
    return safeUserChatHistoryList.filter((user) =>
      user?.receiver?.username?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
  }, [safeUserChatHistoryList, searchQuery]);


  const handleUserClick = (friend) => {
    setZIndex("chat")
    const storedData = {
      id: friend?.lastMessage?.id,
      friend_id: friend?.receiver?.id,
      username: friend?.receiver?.username,
      avatar: friend?.receiver?.avatar,
      chatroomId: friend?.lastMessage?.chat_id,
      created_at: friend?.lastMessage?.created_at,
      is_online: friend?.receiver.is_online,
      unreadcount: friend?.lastMessage?.unreadcount
    }
    setActiveChatUser(storedData);
  }

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour12: true, hour: "numeric", minute: "numeric" };
    const formattedTime = date.toLocaleString("en-US", options);
    return formattedTime;
  };

  return (
    <div className={`chatlist custom-box-chat ${zIndex === "chatList" ? "z-index1" : "z-index-1"}`}>
      <div className="modal-dialog-scrollable">
        <div className="modal-content-chat">
          <div className="chat-header p-4">
            <h4 className="f22-size fw-400 sec-theme mb-4 text-start">All Messages</h4>
            <div className="msg-search my-2 pb-2 gap-2">
              <img src={IMAGES.INPUTSEARCH} className=""></img>
              <input
                type="text"
                className="msg-input"
                id="inlineFormInputGroup"
                placeholder="Search here"
                aria-label="Search here"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>

            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" id="Open-tab" data-bs-toggle="tab" data-bs-target="#Open" type="button" role="tab" aria-controls="Open" aria-selected="true">Open</button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="Closed-tab" data-bs-toggle="tab" data-bs-target="#Closed" type="button" role="tab" aria-controls="Closed" aria-selected="false">Closed</button>
                                                            </li>
                                                        </ul> */}
          </div>

          <div className="modal-body-chat">
            <div className="chat-lists">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="Open"
                  role="tabpanel"
                  aria-labelledby="Open-tab"
                >
                  {
                    filterUserList.length > 0 ? <ul className="chat-list ps-0">
                      {filterUserList?.map((friend, index) => (
                        (friend.lastMessage || activeChatUser?.friend_id === friend?.receiver?.id) && <li key={`chatList ${index}`} style={{ cursor: "pointer" }} onClick={() => handleUserClick(friend)}>
                          <a href="" onClick={(e) => e.preventDefault()} className={`chat-inner-listing ${activeChatUser?.friend_id === friend?.receiver?.id && friend?.receiver?.id != 1 && "active"} ${friend?.receiver?.id == 1 && "asocial-active"}`}>
                            <div className="position-relative">
                              {
                                friend?.receiver?.avatar ? <img
                                  className=" position-relative img-chatprofile message-profile"
                                  src={IMAGEURL + friend?.receiver?.avatar}
                                /> : <img
                                  className=" position-relative img-chatprofile  message-profile"
                                  src={IMAGES.USER_ICON}
                                />
                              }
                              {
                                friend.receiver.is_online == "1" && <span className="chatactive-dot"></span>
                              }
                            </div>
                            <div className="asocial-name">
                              <h3 className=" text-start">{friend?.receiver?.id == 1 ? "Asocial" : friend?.receiver?.username}</h3>
                              {friend.receiver.id == "1" && <img className="mx-2" src={IMAGES.ANNOUNCEMENT} />}
                              {
                                friend?.receiver?.id != 1 ? <>
                                  {
                                    friend?.lastMessage.type == "IMAGE" || friend?.lastMessage?.message?.includes("/images") ? (
                                      <>
                                        <div className="photo-container">
                                          <img src={IMAGES.CAMERAIMG} alt="camera" className="camera-img" />
                                          <p className="photo-text">Photo</p>
                                        </div>
                                      </>
                                    ) : (
                                      <p className="mb-0 one-line fw-400 para-text text-start">
                                        {friend?.lastMessage?.message?.length > 40
                                          ? `${friend?.lastMessage?.message.substring(0, 40)}...`
                                          : friend?.lastMessage?.message}
                                      </p>
                                    )
                                  }</> : ""
                              }

                            </div>
                            <div className="ms-auto text-end">
                              <p className="mb-0 fw-400 f14-size text-color time_chat" style={{ width: '65px' }}>
                                {/* {formatDate(friend?.lastMessage?.created_at)} */}

                                {formatTime(friend?.lastMessage?.created_at)}
                              </p>
                              {
                                friend?.receiver?.id == 1 ? <>
                                  {(friend?.lastMessage && friend?.lastMessage?.unreadcount !== 0) && <p className="mb-0 count-msg mt-1">{friend?.lastMessage?.unreadcount}</p>}</>
                                  :
                                  <>
                                    {(friend?.lastMessage && friend?.lastMessage?.unreadcount !== 0) && <p className="mb-0 count-msg ">{friend?.lastMessage?.unreadcount}</p>}
                                  </>
                              }

                            </div>
                          </a>
                        </li>
                      ))}
                      {/* <li>
                      <a href="" className="chat-inner-listing ">
                        <div className=" position-relative">
                          <img
                            className=" position-relative img-chatprofile"
                            src={IMAGES.AVATAR5}
                          />
                          <span className="chatactive-dot"></span>
                        </div>
                        <div className="">
                          <h3>Mehedi Hasan</h3>
                          <p className="mb-0 one-line fw-400 para-text text-start">
                            front end developer
                          </p>
                        </div>
                        <div className="ms-auto text-end">
                          <p className="mb-0 fw-400 f14-size text-color">
                            10:32 AM
                          </p>
                          <p className="mb-0 count-msg">2</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="" className="chat-inner-listing ">
                        <div className=" position-relative">
                          <img
                            className=" position-relative img-chatprofile"
                            src={IMAGES.AVATAR5}
                          />
                          <span className="chatactive-dot"></span>
                        </div>
                        <div className="">
                          <h3>Mehedi Hasan</h3>
                          <p className="mb-0 one-line fw-400 para-text text-start">
                            front end developer
                          </p>
                        </div>
                        <div className="ms-auto text-end">
                          <p className="mb-0 fw-400 f14-size text-color">
                            10:32 AM
                          </p>
                          <p className="mb-0 count-msg">2</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="" className="chat-inner-listing active">
                        <div className=" position-relative">
                          <img
                            className=" position-relative img-chatprofile"
                            src={IMAGES.AVATAR5}
                          />
                          <span className="chatactive-dot"></span>
                        </div>
                        <div className="">
                          <h3>Mehedi Hasan</h3>
                          <p className="mb-0 one-line fw-400 para-text text-start">
                            front end developer
                          </p>
                        </div>
                        <div className="ms-auto text-end">
                          <p className="mb-0 fw-400 f14-size text-color">
                            10:32 AM
                          </p>
                          <p className="mb-0 count-msg">2</p>
                        </div>
                      </a>
                    </li> */}
                    </ul> :
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "50vh" }}
                      >
                        <h4>No user</h4>
                      </div>}

                </div>
                <div
                  className="tab-pane fade"
                  id="Closed"
                  role="tabpanel"
                  aria-labelledby="Closed-tab"
                >
                  <ul className="chat-list ps-0">
                    <li> <a href="" className="chat-inner-listing active">
                      <div className=" position-relative">
                        <img
                          className=" position-relative img-chatprofile"
                          src={IMAGES.AVATAR5}
                        />
                        <span className="chatactive-dot"></span>
                      </div>
                      <div className="">
                        <h3>Mehedi Hasan</h3>
                        <p className="mb-0 one-line fw-400 para-text text-start">
                          front end developer
                        </p>
                      </div>
                      <div className="ms-auto text-end">
                        <p className="mb-0 fw-400 f14-size text-color">
                          10:32 AM
                        </p>
                      </div>
                    </a>
                    </li>
                    <li><a href="" className="d-flex align-items-center">
                      <div className="flex-shrink-0 position-relative">
                        <img
                          className=" position-relative img-chatprofile"
                          src={IMAGES.AVATAR5}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3>Jhon Hasan</h3>
                        <p className="mb-0 one-line fw-400">
                          front end developer
                        </p>
                      </div>
                    </a>
                    </li>
                    <li><a href="" className="d-flex align-items-center">
                      <div className="flex-shrink-0 position-relative">
                        <img
                          className=" position-relative img-chatprofile"
                          src={IMAGES.AVATAR5}
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3>Jhon Hasan</h3>
                        <p className="mb-0 one-line fw-400">
                          front end developer
                        </p>
                      </div>
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ChatList;
