import React, { useState } from "react";
import { IMAGES } from "../../components/images";

const EnterYourOwn = (props) => {
  const { setIsEnterYourOwnModalOpen, setFormData, formData, myAccount, setOwnInterestAdd, handleChipClick
  } = props;
  const [newInterests, setNewInterests] = useState("");
  const [interestsList, setInterestsList] = useState([]);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setNewInterests(value);
    if (error) {
      setError(null);
    }
  };

  const handleValidation = () => {
    const trimmedInterest = newInterests.trim();
    if (trimmedInterest.length === 0 && interestsList.length === 0) {
      setError("Please enter an interest");
      return false;
    }
    if (trimmedInterest.length > 0 && interestsList.includes(trimmedInterest)) {
      setError("Interest already exists");
      return false;
    }
    return true;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmedInterest = newInterests?.trim();
      if (trimmedInterest.length === 0) {
        return;
      }
      if (interestsList.includes(trimmedInterest)) {
        setError("Interest already exists");
      } else {
        setInterestsList([...interestsList, trimmedInterest]);
        setNewInterests("");
        setError(null);
      }
    }
  };


  const handleDelete = (interest) => {
    setInterestsList(interestsList.filter(item => item !== interest));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const trimmedInterest = newInterests.trim();
      const updatedInterestsList = trimmedInterest.length > 0 ? [...interestsList, trimmedInterest] : interestsList;
      console.log(trimmedInterest, "trimmedInterest")
      let formDataInterestsCopy = { ...formData.interests };
      const existingInterests = formDataInterestsCopy.ownInterests || [];
      const updatedInterests = [...existingInterests, ...updatedInterestsList];
      formDataInterestsCopy["ownInterests"] = [...new Set(updatedInterests)];
      setFormData({
        ...formData,
        interests: formDataInterestsCopy,
      });
      handleChipClick("ownInterests", updatedInterestsList)
    }
    setIsEnterYourOwnModalOpen(false);
    setOwnInterestAdd(true)
  };

  return (
    <div className="myaccount-gallery modal-gallery">
      <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered">
        <div className="myaccount-content content-gallery">
          <div className="border-0 position-relative">
            <h3 className="text-center mb-0 fw-500 text-color">Enter your Own</h3>
            <button
              type="button"
              onClick={() => setIsEnterYourOwnModalOpen(false)}
              className="border-0 bg-none close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={IMAGES.CLOSSBUTTON} />
            </button>
          </div>
          <div className="modal-body p-0 pt-5">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={newInterests}
                  type="text"
                  placeholder="Type your interest words here"
                  className="input-field w-100"
                />
              </div>
              <div className="own-interest-head">
                <div className="interest-inner pb-3 myaccount-line border-bottom-0 own-interest pt-3">
                  {interestsList.map((interest, index) => (
                    <div key={index} className="owninterest d-flex align-items-center mx-2 gap-3">
                      <p className="text-white mb-0 fw-400">{interest}</p>
                      <img
                        style={{ cursor: "pointer" }}
                        src={IMAGES.WHITECROSS}
                        className="pt-1 img-fluid"
                        onClick={() => handleDelete(interest)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {error && <p className="text-danger">{error}</p>}
              <div className="pt-5">
                <button type="submit" className="gradient-btn border-0 w-100">Done</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterYourOwn;
