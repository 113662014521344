import React from "react";
import { IMAGES } from "../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const UnLockFeatures = (props) => {
    const {setShowUnlockFeaturesModal} = props;
    const navigate = useNavigate();
    const hanldeRegisterButton = (e)=>{
      e.preventDefault();
        localStorage.clear();
        navigate(ROUTES.SIGNIN)
    }
  return (
    <>
      <div className="myaccount-gallery  modal-gallery">
      <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered  ">
        <div className="myaccount-content content-gallery">
      <div className="myaccount-gallery  modal-gallery">
        <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered  ">
          <div className="myaccount-content content-gallery">
            {" "}
            <div
              className=" border-0 position-relative Text-center"
              style={{ textAlign: "center" }}
            >
              <img src={IMAGES.UNLOCK_FEATURE} className="img-fluid" />
              <button
                type="button"
                onClick={()=>setShowUnlockFeaturesModal(false)}
                className="border-0 bg-none close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={IMAGES.CLOSSBUTTON} />
              </button>{" "}
            </div>
            <div className="modal-body p-0 pt-3 pt-md-5">
              <h3 className="text-start mb-0 fw-500 text-color mb-3">
                Unlock these features with a free Asocial account
              </h3>
              <div className="text-start">
                {" "}
                <ul className="ps-0">
                  <li></li>
                  <li>
                    <p className="feature-list-text fw-500">
                      - Set a profile picture or avatar
                    </p>
                    <p className="feature-list-text fw-500">
                      - Set a custom screen name
                    </p>
                  </li>
                  <li>
                    <p className="feature-list-text fw-500">
                      - Interest-based matching
                    </p>
                  </li>
                </ul>
              </div>
              <div className="pt-3">
                <Link onClick={hanldeRegisterButton} className="gradient-btn border-0 w-100">Register</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default UnLockFeatures;
