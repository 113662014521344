import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../components/images";
import { handleRequest } from "../../../services";
import { BLOCK_USER, FRIEND_UNFRIEND, GET_LOGS, IMAGEURL, REPORT_USER } from "../../../config";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../../components/loader/loader";
import { useSidebarContext } from "../../../context/sidebarContext";
import { Dropdown } from "bootstrap";

const formatDateTime = (dateString) => {

  const date = new Date(dateString);

  const options = { hour12: true, hour: '2-digit', minute: '2-digit' };
  const formattedTime = date.toLocaleTimeString('en-US', options);

  const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  const formattedDateTime = `${formattedTime} ${formattedDate}`;

  return formattedDateTime;

}

const FriendCard = (props) => {
  const { log, getLogs, index, openDropdownIndex, handleDropdownToggle, } = props;
  const navigate = useNavigate();
  const handleAddFriend = async (log) => {
    if (log.status === 0 || log.status === 2) {
      try {
        const response = await handleRequest("GET", FRIEND_UNFRIEND + log?.id);
        toast.info(response.message);
        getLogs();
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleReportUser = async (id) => {
    const data = {
      blocked_user_id: id
    }
    try {
      const response = await handleRequest("POST", REPORT_USER, data);
      getLogs();
      toast.success(response.message)
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }



  const handleBlockUser = async (id) => {
    try {
      const response = await handleRequest("GET", `${BLOCK_USER}?block_user_id=${id}`);
      toast.success(response.message)
      getLogs()
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }
  const handleNavigate = (friend) => {
    if (friend.status === 2) {
      navigate(ROUTES.MESSAGES, { state: { friend: friend } })
      localStorage.setItem("messageNavigate", 0)

    }
  }
  return (
    <div className=" col-md-6 col-xl-6 col-xxl-4 custom-col-4 mb-4">
      <div className="sendrequest-box cus-sendrequest">
        <div style={{ cursor: "pointer" }} onClick={() => handleNavigate(log)} className="profile-online position-relative">
          <img src={log?.avatar ? IMAGEURL + log?.avatar : IMAGES.USER_ICON} />
          {/* <span className="green-dot"></span> */}
        </div>
        <div className="d-flex justify-content-between align-items-start w-100 flex-wrap">
          <p style={{ cursor: (log.status === 0) ? "not-allowed" : "pointer" }} onClick={() => handleNavigate(log)} className="mb-0 online-text text-color fw-500 one-line d-flex flex-column">
            {log?.username}
            <span className="chat-date">{formatDateTime(log?.created_at)}</span>
          </p>

          {log?.account_type === 1 && <> {log?.status === 0 || log?.status === 1 ? <button
            type="button"
            onClick={() => handleAddFriend(log)}
            className="cancel-btn f14-size fw-500 text-color"
          >
            {`${log?.status === 0 ? "Add friend" : "Pending"}`}
          </button> :
            <div className="profile-dot position-relative">
              <img
                onClick={() => handleDropdownToggle(index)}
                style={{ cursor: 'pointer' }}
                src={IMAGES.DOTSCIRCLE}
                className="img-fluid"
              />         {openDropdownIndex == index && <div className="dropdown-profile">
                <ul className="ps-0 mb-0">
                  <li onClick={() => handleAddFriend(log)} style={{ cursor: "pointer" }} className="pb-2 border-bottom f14-size fw-400  text-color text-start">
                    Unfriend
                  </li>
                  <li onClick={() => handleReportUser(log?.id)} style={{ cursor: "pointer" }} className="py-2 border-bottom f14-size fw-400  text-color text-start">
                    Report
                  </li>
                  <li onClick={() => handleBlockUser(log?.id)} style={{ cursor: "pointer" }} className="pt-2  f14-size fw-400  text-danger text-start">
                    Block
                  </li>
                </ul>
              </div>}
            </div>
          }</>}
          {log?.account_type === 0 &&
            <p>(Guest)</p>
          }
        </div>


        {/* { log?.account_type===1 &&  <> { log?.status===0 || log?.status===1 ? <button
        type="button"
        onClick={()=>handleAddFriend(log)}
        className="cancel-btn f14-size fw-500 text-color"
      >
       {`${log?.status===0? "Add friend": "Pending"}`}
      </button>:
          <div className="profile-dot position-relative">
          <img onClick={()=>setShowDropdown(!showDropdown)} style={{cursor:"pointer"}} src={IMAGES.DOTSCIRCLE} className="img-fluid"></img>
         { showDropdown && <div className="dropdown-profile">
            <ul className="ps-0 mb-0">
              <li onClick={()=>handleAddFriend(log)} style={{ cursor:"pointer" }} className="pb-2 border-bottom f14-size fw-400  text-color text-start">
                Unfriend
              </li>
              <li onClick={()=>handleReportUser(log?.id)} style={{ cursor:"pointer" }} className="py-2 border-bottom f14-size fw-400  text-color text-start">
                Report
              </li>
              <li onClick={()=>handleBlockUser(log?.id)} style={{ cursor:"pointer" }} className="pt-2  f14-size fw-400  text-danger text-start">
                Block
              </li>
            </ul>
          </div>}
        </div>
      }</>}
      {log?.account_type===0 && 
        <p>(Guest)</p>
      } */}
        {/* <p>{formatDateTime(log?.created_at)}</p> */}
      </div>
    </div>
  )
}

const VideoChatHistory = () => {
  const [callLogsList, setCallLogsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isSidebarVisible } = useSidebarContext();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };


  const getLogs = async () => {
    setIsLoading(true);
    try {
      const response = await handleRequest("GET", GET_LOGS);
      const filteredList = response.body.filter((log, index, self) =>
        index === self.findIndex((t) => (
          t["id"] === log["id"]
        )))
      setCallLogsList(response?.body);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLogs();
  }, [])

  return (
    <>
      {isLoading ? <div className="web-cam cus-inner-spinner"><Spinner /></div> : <div className={`content-inner ${!isSidebarVisible && "hamburger-space"}`}>
        <h3>Chat History</h3>
        <div className="tabs-inner-content mt-4">
          <h4 className="text-color ">{`Chat History (${callLogsList?.length})`}</h4>
          <div className="scroll-inner">
            <div className="row mt-4">
              {callLogsList?.length > 0 ?
                callLogsList?.map((log, index) => (
                  <FriendCard log={log} getLogs={getLogs} index={index} openDropdownIndex={openDropdownIndex}
                    handleDropdownToggle={handleDropdownToggle} />
                )) :
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "50vh" }}
                >
                  <h4>No chat history yet</h4>
                </div>
              }

            </div>
          </div>
        </div>

      </div>}
    </>
  );
};

export default VideoChatHistory;
