import React, { useState } from "react";
import { IMAGES } from "../../components/images";
import { Link, useNavigate } from "react-router-dom";
const ConfirmModal = (props) => {
    const { setConfirmation, handleLeaveCall, setLeave, check } = props;

    return (
        <div className="myaccount-gallery  modal-gallery">
            <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered ">
                <div className="myaccount-content content-gallery">
                    <div className=" border-0 position-relative">
                        <h4 className="text-center mb-0 fw-500 text-color mt-3">
                            Are you sure you want to Leave this call?{" "}
                        </h4>
                        <button
                            type="button"
                            onClick={() => setConfirmation(false)}
                            className="border-0 bg-none close-btn delete-close-btn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={IMAGES.CLOSSBUTTON} />
                        </button>
                    </div>
                    <div className="modal-body p-0 pt-2">
                        <form>


                            <div className="d-flex justify-content-between align-itemns-center gap-4">
                                <Link
                                    onClick={() => setConfirmation(false)}
                                    className="gradient-btn cus-gradient-btn border-0 w-100 mt-4"
                                >
                                    No
                                </Link>
                                <Link
                                    onClick={() => {
                                        if (check == "randomCall") {
                                            setLeave(true)
                                            handleLeaveCall()
                                        }
                                        else {
                                            handleLeaveCall("cutCall")
                                        }
                                    }}
                                    className="gradient-btn cus-gradient-btn border-0 w-100 mt-4"
                                >
                                    Yes
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
