
import React, { useState } from 'react'
import { IMAGES } from '../../../../components/images'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../routes/routes'
import Spinner from '../../../../components/loader/loader'
import { useNavigate } from 'react-router-dom'

const AccountCreated = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      navigate(ROUTES.HOME);
      setIsLoading(false);
    }, 5000)
  }
  return (

    <div className=" text-center">
      <img src={IMAGES.CREATE_LOGO} className="img-fluid" />
      <h3 className="pb-2 text-black fw-600">Account Created!</h3>
      <p className="fw-500 text-black text-start" style={{lineHeight:'22px'}}>You're almost there. Just remember to always treat your chat partners with respect, and keep Asocial a fun place for people to interact.</p>
      <p className="fw-500 text-black text-start" style={{lineHeight:'22px'}}>If you see people breaking our rules, use the reporting facility to notify us.</p>
      <p className="fw-500 text-black text-start" style={{lineHeight:'22px'}}>The Asocial Team</p>

      {
        isLoading ?
          <div className="loader-style">
            <Spinner />
          </div>
          :
          <Link onClick={onSubmit} style={{ cursor: "pointer" }} className="gradient-btn border-0 w-100 mt-4">Get started</Link>}
    </div>

  )
}

export default AccountCreated