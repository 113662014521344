import React, {
    useEffect,
    useRef,
  } from 'react';
  
  const VideoPlayer = ({ videoTrack, style, className }) => {
    const ref = useRef(null);
  
    useEffect(() => {
      const playerRef = ref.current;
      if (!videoTrack) return;
      if (!playerRef) return;
  
      videoTrack?.play(playerRef);
      videoTrack._player.videoElement.style["object-fit"] = "contain"  
      return () => {
        videoTrack?.stop();
      };
    }, [videoTrack]);
  
    return <div ref={ref}  className={className} style={style}></div>;
  };

  export default VideoPlayer;