import React, { useState } from "react";
import { IMAGES } from "../../components/images";
import { Link, useNavigate } from "react-router-dom";
import { handleRequest } from "../../services";
import { DELETE_ACCOUNT } from "../../config";
import {ROUTES} from  "../../routes/routes";
import Spinner from "../../components/loader/loader";

const AreYouSure = (props) => {
  const navigate = useNavigate();
 const {setIsDeleteModalOpen} = props;
 const [isLoading, setIsLoading] = useState(false);
 const handleDelete = async(e)=>{
  e.preventDefault()
   setIsLoading(true);
       try {
         const response = await handleRequest("GET", DELETE_ACCOUNT);
         if(response.code===200){
          localStorage.clear();
          navigate(ROUTES.SIGNIN);
         }
         setIsDeleteModalOpen(false)
       } catch (error) {
         console.error(error);
       }
     setIsLoading(false)
   
 }
  return (
    <div className="myaccount-gallery  modal-gallery">
      <div className="myaccount-dialog myaccount-dialog-centered modal-dialog-centered ">
        <div className="myaccount-content content-gallery">
          <div className=" border-0 position-relative">
            <h4 className="text-center mb-0 fw-500 text-color mt-3">
              Are you sure you want to delete this account?{" "}
            </h4>
            <button
              type="button"
              onClick={() => setIsDeleteModalOpen(false)}
              className="border-0 bg-none close-btn delete-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src={IMAGES.CLOSSBUTTON} />
            </button>
          </div>
          <div className="modal-body p-0 pt-2">
            <form>
              {/* <div className="form-group">
                <input
                  onChange={handleChange}
                  value={newInterests}
                  type="text"
                  placeholder="Type your interest words here"
                  className="input-field w-100"
                />
              </div> */}
           
              <div className="d-flex justify-content-between align-itemns-center gap-4">
                <Link
                  onClick={()=>setIsDeleteModalOpen(false)}
                  className="gradient-btn cus-gradient-btn border-0 w-100 mt-4"
                >
                  No
                </Link>
                {isLoading?
                <div className="loader-style">
                <Spinner />
                </div>
                :<Link
                  onClick={handleDelete}
                  className="gradient-btn cus-gradient-btn border-0 w-100 mt-4"
                >
                  Yes
                </Link>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreYouSure;
