import React from 'react';
import { ACCEPT_REJECT, CREATE_ROOM } from "../../../config";
import { handleRequest } from "../../../services";

const FriendRequestCustomToast = ({ sender_id, sender_name, setFriendRequest, getOtherUserDetail }) => {
  const acceptRejectFriendRequest = async (id, status) => {
    const data = {
      "sender_id": id,
      "status": status
    }
    try {
      const response = await handleRequest("POST", ACCEPT_REJECT, data);
      if (response.message == "Request accepted successfully") {
        setFriendRequest(2)
        const createRoomResponse = await handleRequest("GET", CREATE_ROOM + id);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <h6 className="heading-custom-alert-text">You've got a new friend request from {sender_name}.</h6>
      <div>
        <button
          onClick={() => acceptRejectFriendRequest(sender_id, 1)}
          type="button"
          className="cancel-btn f14-size fw-500 text-color me-2"
        >
          Reject
        </button>
        <button
          onClick={() => acceptRejectFriendRequest(sender_id, 2)}
          type="button"
          className="sec-bg cancel-btn f14-size fw-500 text-color"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default FriendRequestCustomToast;