import React from 'react'
import SignUp from './components/signUp'

const SignUpLayout = () => {
  return (
    <>
      <div className="bg-white w-100 box-shadow login-radius">
        <SignUp />
      </div>
    </>
  )
}

export default SignUpLayout

