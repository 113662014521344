import React from 'react';

const ProgressBar = (props) => {
  const { step, setStep } = props
  const labels = ["Name", "Gender", "Avatar", "Interests"];

  const handleStepNavigation = (index)=>{
   if(index+1<step){
    setStep(index+1);
   }
  }

  const renderProgressBar = () => {
    return labels.map((label, index) => (
      <li style={{ cursor:"pointer" }} onClick={()=>handleStepNavigation(index)} key={index} className={`progressbar-dots ${step === index + 1 ? 'active' : ''}`}>
        <p className="number-progress mb-0">{index + 1}</p> <span>{label}</span>
      </li>
    ));
  };

  return (
    <div className="progress-wrap margin-bottom-5">
      <div className="line-progress-bar">
        <div className="line"></div>
        <ul className="checkout-bar">
          {renderProgressBar()}
        </ul>
      </div>
    </div>
  );
};

export default ProgressBar;
