import React, { useState } from "react";
import { IMAGES } from "../../../components/images";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import SignIn from "./components/signin";
import Otp from "./components/otp";

const SignInLayout = () => {
  const [userData, setUserData] = useState(null)
  const [phone, setPhone] = useState("");

  return (
    <>
  {!userData? <SignIn
   userData={userData}
   setUserData={setUserData}
   phone={phone}
   setPhone={setPhone}
   />:
   <Otp
    phone={phone}
    userData={userData}
   />}
    </>
  );
};

export default SignInLayout;
