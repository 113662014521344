import React from "react";
import { IMAGES } from "../../../../components/images";

const SecondStep = (props) => {
  const { setFormData, formData, setStep } = props;

  const handleGenderSelection = (genderCode) => {
    setFormData({ ...formData, gender: genderCode });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(3);
  }
  return (
    <>
      <div className="heading mt-2 mt-sm-0">
        <h3 className="">Gender
          {/* <span style={{ color:"lightgray" }}>(Optional)</span> */}
        </h3>
        {/* <p className="fw-400">Only One Chance to Choose.</p> */}
      </div>
      <div className="padding-top-4 ">
        <form onSubmit={handleSubmit}>
          <div className="padding-top-4 pb-0 pb-sm-3">
            <div className="row">
              <div className="col-6 col-md-4 mb-3">
                <div className="gender-box ">
                  <div onClick={() => handleGenderSelection(1)} style={{ cursor: "pointer" }} className={`gender-inner ${formData.gender === 1 ? "active" : ""}`}>
                    <img src={formData.gender === 1 ? IMAGES.MALE_SELECTED : IMAGES.MALE_UNSELECTED} className="gender-inner-first-img" />
                  </div>
                  <p className="text-center pt-1">Male</p>
                </div>
              </div>
              <div className="col-6 col-md-4 mb-3">
                <div className="gender-box ">
                  <div onClick={() => handleGenderSelection(2)} style={{ cursor: "pointer" }} className={`gender-inner ${formData.gender === 2 ? "active" : ""}`}>
                    <img src={formData.gender === 2 ? IMAGES.FEMALE_SELECTED : IMAGES.FEMALE_UNSELECTED} className="gender-inner-img" />
                  </div>
                  <p className="text-center pt-1">Female</p>
                </div>
              </div>
              <div className="col-6 col-md-4 mb-3">
                <div className="gender-box ">
                  <div onClick={() => handleGenderSelection(3)} style={{ cursor: "pointer" }} className={`gender-inner ${formData.gender === 3 ? "active" : ""}`}>
                    <img src={formData.gender === 3 ? IMAGES.NOBINARY_SELECTED : IMAGES.NOBINARY_UNSELECTED} className="gender-inner-img" />
                  </div>
                  <p className="text-center pt-1">Non-binary</p>
                </div>
              </div>
            </div>
          </div>
          <div className="top-space">
            <button className="gradient-btn border-0 w-100">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SecondStep;
