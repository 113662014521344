export const BASEURL = import.meta.env.VITE_BASE_URL
export const IMAGEURL = import.meta.env.VITE_IMAGE_URL
export const SOCKETURL = import.meta.env.VITE_SOCKET_URL
export const SIGNIN = "/auth/sign-up";
export const COMPLETE_REGISTRATION = "/auth/complete-profile";
export const OTP_VERIFICATION = "/auth/otp-verfication";
export const RESEND_OTP = "/auth/resend-otp";
export const UPLOADIMAGE = "/auth/single-file-upload";
export const DELETE_ACCOUNT = "/auth/delete-account";
export const SOCIAL_LOGIN = "/auth/social-login";
export const GENERATE_RTC_TOKEN = "/auth/generate-rtc-token";
export const GET_MY_DETAIL = "/auth/get-my-detail";
export const CREATE_ROOM = "/chat/create-room?receiver_id=";
export const CREATE_LOG = "/chat/create-log?receiver=";
export const ONLINE_USER = "/auth/online-user";
export const GET_ALL_LIVE_USERS = "/auth/getall-live-users";
export const OTHER_USER_DETAIL = "/auth/other-profile";
export const SET_YOUR_PROFILE = "/auth/set-your-profile";
export const FRIEND_UNFRIEND = "/chat/friend-unfriend?receiver_id=";
export const REPORT_USER = "/auth/report-user";
export const GET_ALL_CONNECTION = "/chat/get-allconnection";
export const ACCEPT_REJECT = "/chat/accept-reject";
export const BLOCK_USER = "/auth/block-user";
export const SEND_MESSAGE = "/chat/send-message";
export const GET_ROOM_MESSAGE = "/chat/web-room-message?chat_id=";
export const CHAT_USER_HISTORY = "/chat/chat-user-history";
export const GET_LOGS = "/chat/get-logs";
export const USER_TYPING = "/auth/user-typing?user_id=";
export const UPDATE_SWIPELIMIT = "/auth/update-swipe-limit";
export const GET_BLOCKED_USERS = "/auth/get-block-user";
export const GET_MATCHED_INTEREST_LIST = "/auth/match-interest-list";
export const MAKE_USER_ONLINE = "/auth/make-user-online";
export const GET_ONLINE_USERS = "/auth/my-online-friends"
export const GET_MATCHED_INTEREST_LIST_HISTORY = "/auth/match-interest-list-caller"
export const GET_MATCHED_INTEREST_STATUS = "/auth/interest-matching-status"
export const GET_MATCHED_INTEREST_LISTING = "/auth/intrests-list"
export const REJECT_CALL = "/auth/reject-call"
export const FRIEND_CALL_END = "/auth/end-Call"
