import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import interests from "../../../../assets/data/interests.json";
import EnterYourOwn from "../../../modals/enterOwnInerestsModal";
import { IMAGES } from "../../../../components/images";
import { handleRequest } from "../../../../services";
import { COMPLETE_REGISTRATION } from "../../../../config";
import Spinner from "../../../../components/loader/loader";

const FourthStep = (props) => {
  const { setFormData, formData, setStep, setEditInterestsModal, setEditDetails, owninterest, editDetails, interests, catIds } = props;
  const [isEnterYourOwnModalOpen, setIsEnterYourOwnModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ownInterestAdd, setOwnInterestAdd] = useState(false);
  const ownInterestsRef = useRef(null);

  useEffect(() => {
    if (ownInterestAdd && ownInterestsRef.current) {
      ownInterestsRef.current.scrollIntoView({ behavior: "smooth" });
      setOwnInterestAdd(false);
    }
  }, [ownInterestAdd]);

  const handleChipClick = (category, subcategory) => {
    const subcategories = Array.isArray(subcategory) ? subcategory : [subcategory];
    const interestsObjectCopy = { ...(formData?.interests || {}) };
    if (category in interestsObjectCopy) {
      subcategories.forEach(sub => {
        if (interestsObjectCopy[category]?.includes(sub)) {
          const indexToRemove = interestsObjectCopy[category].indexOf(sub);
          interestsObjectCopy[category].splice(indexToRemove, 1);
        } else {
          interestsObjectCopy[category] = [...interestsObjectCopy[category], sub];
        }
      });
    } else {
      interestsObjectCopy[category] = [...subcategories];
    }
    setFormData({
      ...formData,
      interests: interestsObjectCopy,
    });
    const categoryNameMapping = {
      'ownInterests': owninterest,
    };

    const transformedInterests = transformInterests(interestsObjectCopy, categoryNameMapping, catIds);
    localStorage.setItem("transformedInterests", JSON.stringify(transformedInterests));
  };


  const transformInterests = (interestsObjectCopy, categoryNameMapping, categoryToIdMap) => {
    return Object.keys(interestsObjectCopy).map(category => {
      const mappedCategoryName = categoryNameMapping[category] || category;
      const cat_id = categoryToIdMap[mappedCategoryName];
      const interestsArray = interestsObjectCopy[category]
        .filter(interest => interest)
        .join(', ')
        .split(', ')
        .map(interest => interest.trim());
      const interests = interestsArray.join(', ');
      return {
        cat_id,
        interests
      };
    });
  };


  const handleOwnInterestsDelete = (subcategory) => {
    const interestsObjectCopy = { ...formData.interests };
    if (interestsObjectCopy.ownInterests) {
      interestsObjectCopy.ownInterests = interestsObjectCopy.ownInterests.filter(item => item !== subcategory);
      if (interestsObjectCopy.ownInterests.length === 0) {
        delete interestsObjectCopy.ownInterests;
      }
      setFormData({
        ...formData,
        interests: interestsObjectCopy,
      });
    }
   };

  const generateUniqueId = () => {
    return `user_${Date.now()}`;
  };

  const handleClose = () => {
    setEditInterestsModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (setStep) {
      let userId = localStorage.getItem("uniqueUserId");
      if (!userId) {
        userId = generateUniqueId();
        localStorage.setItem("uniqueUserId", userId);
      }
      const userData = JSON.parse(localStorage.getItem("userData"));
      let mobile;
      if (userData?.userdata?.mobile_no) {
        mobile = userData.userdata.mobile_no;
      } else if (userData?.mobile_no) {
        mobile = userData.mobile_no;
      }
      let id;
      if (userData?.userdata?.id) {
        id = userData.userdata.id;
      } else if (userData?.id) {
        id = userData.id;
      }
      const data = {
        mobile_no: mobile,
        unique_id: localStorage.getItem("uniqueId"),
        name: formData.screenName,
        gender: formData.gender,
        avatar: formData.avatar,
        // dob: dayjs(formData.dateOfBirth).format("YYYY-MM-DD"),
        interests_data: JSON.parse(localStorage.getItem("transformedInterests")),
        flag: localStorage.getItem("flag")
      };
      setLoading(true);
      try {
        const response = await handleRequest("POST", COMPLETE_REGISTRATION, data);
        console.log(response);
        if (response.code === 200) {
          if (response.body.token && response.body.userdata) {
            localStorage.setItem("token", JSON.stringify(response.body.token));
            localStorage.setItem("userData", JSON.stringify(response.body.userdata));
          } else {
            localStorage.setItem("userData", JSON.stringify(response.body));
          }
          setStep(5);
          localStorage.removeItem("transformedInterests")
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    else {
      const editDetailsCopy = { ...editDetails };
      const interestsFromForm = formData?.interests || {};
      if (interestsFromForm["ownInterests"]) {
        const ownInterestString = interestsFromForm["ownInterests"].join(', ');
        const existingOwnInterest = editDetailsCopy.intrests_list.find(item => item.category_name == owninterest);
        if (existingOwnInterest) {
          editDetailsCopy.intrests_list = editDetailsCopy.intrests_list.map(item =>
            item.category_name == owninterest
              ? { ...item, interests: ownInterestString }
              : item
          );
        }
        else {
          editDetailsCopy.intrests_list.push({
            id: Date.now(),
            cat_id: catIds[owninterest],
            interests: ownInterestString,
            category_name: owninterest
          });
        }
      }
      Object.keys(interestsFromForm).forEach((category) => {
        if (category !== owninterest) {
          const existingCategory = editDetailsCopy.intrests_list.find(item => item.category_name === category);
          const newInterests = interestsFromForm[category].filter(Boolean);
          if (existingCategory) {
            const updatedInterests = [...new Set([...newInterests])].join(', ');

            editDetailsCopy.intrests_list = editDetailsCopy.intrests_list.map(item =>
              item.category_name === category
                ? { ...item, interests: updatedInterests }
                : item
            );
          } else {
            editDetailsCopy.intrests_list.push({
              id: Date.now(),
              cat_id: catIds[category],
              interests: newInterests.join(', '),
              category_name: category
            });
          }
        }
      });

      editDetailsCopy.intrests_list = editDetailsCopy.intrests_list.filter(item =>
        Object.keys(interestsFromForm).includes(item.category_name)
      );
      setEditDetails(editDetailsCopy);
      setEditInterestsModal(false);
    }
  }


  return (
    <>
      <div className="heading">
        {!setStep && (
          <img
            onClick={handleClose}
            style={{
              float: "right",
              maxWidth: "100%",
              height: "auto",
              cursor: "pointer",
            }}
            src={IMAGES.CLOSSBUTTON}
          />
        )}

        <h3 className="">Your Interests</h3>

        <p className="fw-400">Pick up things you Love.</p>
      </div>
      <div className="pt-3">
        <form onSubmit={handleSubmit}>
          <div className="padding-both avatar-height newavatar-height scroll-show thin-scroll">
            {interests?.map((interest) => (
              <React.Fragment key={interest?.category}>
                <p key={interest} className="text-color f20-size fw-400">
                  {interest.category}
                </p>
                <div className="interest-inner pt-0 pb-3" style={{ gap: '10px' }}>
                  {interest?.subcategories?.map((subcategory) => (
                    <p
                      key={subcategory}
                      onClick={() => handleChipClick(interest.category, subcategory, interest.cat_id)}
                      style={{ cursor: "pointer" }}
                      className={`interest-btn mb-0 ${formData?.interests?.[interest?.category]?.includes(subcategory)
                        ? "active"
                        : ""
                        }`}
                    >
                      {subcategory}
                    </p>
                  ))}
                </div>
              </React.Fragment>
            ))}

            {formData?.interests?.ownInterests?.length > 0 && (
              <div ref={ownInterestsRef}>
                <p className="text-color f20-size fw-400 pt-5">Own Interest</p>
                <div className="interest-inner pt-4 pb-5" style={{ cursor: "pointer" }}
                >
                  {formData.interests.ownInterests?.map((subcategory) => (
                    <div
                      // onClick={() => handleChipClick("ownInterests", subcategory)}
                      key={`ownInterests_${subcategory}`}
                      className="owninterest d-flex align-items-center gap-2 px-2"
                    >
                      <p className="text-white mb-0">{subcategory}</p>
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOwnInterestsDelete(subcategory)}
                        src={IMAGES.WHITECROSS}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <p className="with-bg py-1 py-sm-3">
              <span className="loginwith fw-400 text-color f20-size">OR</span>
            </p>
            <Link onClick={() => setIsEnterYourOwnModalOpen(true)} className="skip-btn w-100 fw-500 f18-size">
              Enter Your Own
            </Link>
            {loading ? (
              <div className="loader-style">
                <Spinner />
              </div>
            ) : 
            <>{
              JSON.parse(localStorage.getItem("transformedInterests")) == null ?
              (
                <button type="submit" className="gradient-btn border-0 w-100 mt-4">
                  Skip
                </button>
              ): (
                <button type="submit" className="gradient-btn border-0 w-100 mt-4">
                  Submit
                </button>
              )
            }</>
            
           }
          </div>
        </form>
      </div>
      {isEnterYourOwnModalOpen && (
        <EnterYourOwn
          setIsEnterYourOwnModalOpen={setIsEnterYourOwnModalOpen}
          setFormData={setFormData}
          formData={formData}
          setOwnInterestAdd={setOwnInterestAdd}
          handleChipClick={handleChipClick}
        />
      )}
    </>
  );
};

export default FourthStep;