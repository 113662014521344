import React from "react";

const Tabs = (props) => {
  const {tabNames, activeTab, setActiveTab} = props;
  const handleTabSwitch = (index)=>{
    setActiveTab(index)
  }
  return (
    <div className="tabs-wrapper d-flex mt-4 tabs-scroll">
    {tabNames.map((tab, index) => (
      <div key={index} style={{cursor:"pointer" }} onClick={()=>handleTabSwitch(index)} className={`tabs-inner ${index === activeTab? 'active' : ''}`}>
        <p className="mb-0">{tab}</p>
      </div>
    ))}
  </div>
  );
};

export default Tabs;
