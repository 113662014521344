import React, { useState,useEffect } from "react";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import FourthStep from "./fourthStep";
import ProgressBar from "./progressBar";
import AccountCreated from "./accountCreated";
import { GET_MATCHED_INTEREST_LISTING } from "../../../../config";
import { handleRequest } from "../../../../services";

const fields = {
  screenName: "",
  dateOfBirth: null,
  gender: null,
  avatar: null,
  interests: {},
};

const SignUp = () => {
  const [step, setStep] = React.useState(1);
  const [formData, setFormData] = useState(fields);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false); 
   const[interests,setInterests] = useState([]) 
   const[catIds,setCatIds] = useState([]) 
   const[owninterest,setOwnInterest] = useState("")

   const getInterestList = async () => {
    try {
      const response = await handleRequest("GET", GET_MATCHED_INTEREST_LISTING);
      const data = response.body;
      const transformedData = data
        .filter(item => item.type !== 2)
        .map(item => ({
          category: item.name,
          cat_id: item.id,
          subcategories: item.intrest_list.map(sub => sub.name),
          type:item.type
        }));
      setInterests(transformedData);
      const ownInterest = data.find(item => item.type === 2);
      setOwnInterest(ownInterest.name)
      console.log(response.body)
      const categoryToIdMap = data.reduce((map, item) => {
        map[item.name] = item.id;
        return map;
      }, {});
      setCatIds(categoryToIdMap)
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    getInterestList();
  }, []);


  return (
    <>
    { step<5 &&  <ProgressBar step={step} setStep={setStep}/>}

      {step === 1 && (
        <FirstStep
          setFormData={setFormData}
          formData={formData}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <SecondStep
          setFormData={setFormData}
          formData={formData}
          setStep={setStep}
        />
      )}
      {step === 3 && (
        <ThirdStep
          setFormData={setFormData}
          formData={formData}
          setStep={setStep}
          setSelectedAvatarIndex={setSelectedAvatarIndex}
          selectedAvatarIndex={selectedAvatarIndex}
          isImageUploaded={isImageUploaded}
          setIsImageUploaded={setIsImageUploaded}
        />
      )}
      {step === 4 && (
        <FourthStep
          setFormData={setFormData}
          formData={formData}
          setStep={setStep}
          interests={interests}
          setInterests={setInterests}
          catIds={catIds}
          owninterest={owninterest}

        />
      )}
      {step === 5 && (
        <AccountCreated 
        />
      )}
    </>
  );
};

export default SignUp;
