import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../../components/images";
import { handleRequest } from '../../../../services';
import { BLOCK_USER, FRIEND_UNFRIEND, GET_ALL_CONNECTION, GET_ONLINE_USERS, IMAGEURL, REPORT_USER } from '../../../../config';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../../../components/loader/loader";


const FriendCard = (props) => {
  const { friend, getFriendsList } = props;
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const handleMessageNavigation = (friend) => {
    navigate(ROUTES.MESSAGES, { state: { friend: friend } })
  }

  const handleAddFriend = async (id) => {
    try {
      const response = await handleRequest("GET", FRIEND_UNFRIEND + id);
      toast.info(response.message);
      getFriendsList();
    } catch (error) {
      console.log(error)
    }
  }

  const handleReportUser = async (id) => {
    const data = {
      blocked_user_id: id
    }
    try {
      const response = await handleRequest("POST", REPORT_USER, data);
      getFriendsList();
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }

  const handleBlockUser = async (id) => {
    const data = {
      blocked_user_id: id
    }
    try {
      const response = await handleRequest("GET", `${BLOCK_USER}?block_user_id=${id}`, data);
      console.log(response, "Report User....");
      toast.success(response.message)
      getFriendsList();
      return response.body;
    } catch (error) {
      console.log(error)
    }
  }
  return (
    friend.chatroomId != null && (
      <div className="col-lg-6 col-xl-4 col-xxl-4 mb-4">
        <div className="online-box">
          <div className="profile-online position-relative" style={{ cursor: "pointer" }}
            onClick={() => handleMessageNavigation(friend)}
          >
            <img src={friend?.avatar ? IMAGEURL + friend?.avatar : IMAGES.USER_ICON} alt={`${friend.username}'s avatar`} />
            <span className="green-dot"></span>
          </div>
          <p
            onClick={() => handleMessageNavigation(friend)}
            style={{ cursor: "pointer" }}
            className="mb-0 online-text text-color fw-500 one-line"
          >
            {friend.username}
          </p>
          <div className="profile-dot position-relative">
            <img
              onClick={() => setShowDropdown(!showDropdown)}
              style={{ cursor: "pointer" }}
              src={IMAGES.DOTSCIRCLE}
              className="img-fluid"
              alt="dots"
            />
            {showDropdown && (
              <div className="dropdown-profile">
                <ul className="ps-0 mb-0">
                  <li
                    onClick={() => handleAddFriend(friend?.friend_id)}
                    style={{ cursor: "pointer" }}
                    className="pb-2 border-bottom f14-size fw-400 text-color text-start"
                  >
                    Unfriend
                  </li>
                  <li
                    onClick={() => handleReportUser(friend?.friend_id)}
                    style={{ cursor: "pointer" }}
                    className="py-2 border-bottom f14-size fw-400 text-color text-start"
                  >
                    Report
                  </li>
                  <li
                    onClick={() => handleMessageNavigation(friend)}
                    style={{ cursor: "pointer" }}
                    className="py-2 border-bottom f14-size fw-400 text-color text-start"
                  >
                    Message
                  </li>
                  <li
                    onClick={() => handleBlockUser(friend?.friend_id)}
                    style={{ cursor: "pointer" }}
                    className="pt-2 f14-size fw-400 text-danger text-start"
                  >
                    Block
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* <button type="btn" className="cancel-btn f14-size fw-500 text-color">Cancel</button> */}
        </div>
        <ToastContainer theme="colored" />
      </div>
    )
  );
}

const Online = () => {
  const [friendsList, setFriendsList] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  const getFriendsList = async () => {
    setIsloading(true);
    try {
      const response = await handleRequest("GET", `${GET_ALL_CONNECTION}?type=3`);
      setFriendsList(response.body)

    } catch (error) {
      console.log(error)
    }
    setIsloading(false);
  }

  useEffect(() => {
    getFriendsList();
  }, [])
  console.log(friendsList?.filter(item => item?.is_online == 1 && item?.username !== null), "gfhjk")
  return (
    <>
      {isLoading ? (
        <div className="web-cam inner-spinner">
          <Spinner />
        </div>
      ) : (
        <div className="tabs-inner-content mt-4">
          <h4 className="text-color">Total Online ({friendsList?.filter(item => item.is_online == 1 && item.username != null).length})</h4>
          <div className="scroll-inner cus-scroll-inner">
            <div className="row mt-4">
              <div className="col-12">
                {friendsList != null && friendsList.filter(item => item.is_online == 1 && item.username != null).length > 0 ? (
                  <div className="row">
                    {friendsList
                      .filter(item => item.is_online == 1 && item.username != null)
                      .map(friend => (
                        <FriendCard key={friend.id} friend={friend} getFriendsList={getFriendsList} />
                      ))}
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "50vh" }}
                  >
                    <h4>No online yet</h4>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default Online;
