export const ROUTES = {
  SIGNIN: "/",
  SIGNUP: "/signup",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  // LANDINGPAGE: "/",
  TRYFREE:"/try_free",
  FRIENDS:"/friends",
  MESSAGES:"/messages",
  VIDEO_CHAT_HISTORY:"/video_chat_history",
  HOME:"/home",
  VIDEO_CALL :"/video_call",
};


